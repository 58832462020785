import React, { useState, useEffect} from 'react';
import { Modal, Button, Form, Table } from 'react-bootstrap';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import api from '../conexao.js';
import { format } from 'date-fns';
import logOut from '../logOut';
import HorariosAgenda from './horariosAgenda.js';



export default function Agenda(){

    const [data, setData] = useState(new Date());
    const [codMed, setCodMed] = useState();

    const [medicos, setMedicos] = useState([]); 

    const [dateRouter, setDateRouter] = useState('');
    const [disableBtnAgenda, setDisableBtnAgenda] = useState(true);
    const [click, setClick] = useState(true)
    let cMed;
    let tokin = localStorage.getItem('token')
    const [modalMSG, setModalMSG] = useState(false);
    const [MSG, setMSG] = useState();
    const [modalEncaixe, setModalEncaixe] = useState(false);
    const [horaEncaixe, setHoraEncaixe] = useState();
    // const [encaixe, setEncaixe] = useState("");
    const [encaixe, setEncaixe] = useState();

useEffect( () => {   
    const buscaMedico = async () => { 
            await api.get('/medico',{
                params: {
                    nome: '%'
                }
            }).then( result => {
                setCodMed(result.data[0].CODIGO)    
                setMedicos(result.data)     
                cMed = result.data[0].CODIGO
            }).catch(
                
                (error) => {
                    setMSG(logOut(error))
                    setModalMSG(true)
                }
                
                )
        }

        buscaMedico(); 
       
        // console.log(click)

},[]);   



// useEffect( () => {
//     async function SearchAgenda(){
//         await api.get('/imprimeagenda', {
//             params: {
//                 med: codMed,
//                 data: format(data, 'yyyy-MM-dd')
//             }
//         }).then( result => {
            
//         }).catch(
            
//             (error) => {
//             setMSG(logOut(error))
//             setModalMSG(true)
//           }
//         )
//     }

//     SearchAgenda()
// }, [codMed, data])


function handleClicaDia(){
    
    setData()
    setClick(false)
    setEncaixe()
    
}

function atualiza()
{
    setClick(false)
    setClick(true)  

}

function onChange(date){
    setData(date);
    setClick(true)
}        

function trocaMedico(cod){
    var dt = new Date();
    setData(dt)
    setCodMed(cod)
    setClick(true)
}

function fEncaixe(hora) {
    
   
   let x = {CODIGO : 0,
        COD_MEDICO : 0,
        COD_PACIENTE : 0,
        COD_SITUACAO : 0,
        COD_STATUS : 0,
        DATA : data,
        HORA : hora,
        Paciente: {CODIGO: 0, NOME: "", 
        CEL: "",
        PLANO: ""},
        Procedimento: {DESCRICAO: ""},
        Status: {DESCRICAO: ""},
        VALOR: 0, 
        ENCAIXE: 1                                     
    }
    setEncaixe(x);
    // console.log(click)
    setModalEncaixe(false)
    setClick(false)
    

}

function fModalEnxaixe(){
    if (modalEncaixe) {
        setModalEncaixe(false)} else {
            setHoraEncaixe()
            setModalEncaixe(true)}
}

const formataMesAnoCalendar = (locale, date) => {
    return date.toLocaleString(locale, {month: 'short', year: 'numeric' })

}

    return (
        <div className="container-fluid  table-hover table-responsive" style={{backgroundColor: "#e6eff5"}}>
            
            <div className="row">
                <div className="col-md-2">   
                    <br />
                        Selecione o Médico:     
                        <Form.Select  size="sm" as="select" value={codMed} onChange={e => trocaMedico(e.target.value)}>
                            {medicos.map( med => (
                                <option value={med.CODIGO}>{med.NOME}</option>
                            ))}
                        </Form.Select> 

                </div>
                
                <div className="col-md-8" 
                // style={{align:"right"}}
                >
                    <br />
                    <br />
                    <center style={{fontSize:"20px", fontFamily:"sans-serif" }}>{format(data, 'dd/MM/yyyy')}</center>    
                </div>                              
                                                       
            </div>
            <div className="row">
                <div className="col-md-2">
                    <div > 
                        <br />
                        <Calendar                            
                            className="react-calendar"
                            value={data}
                            onChange={onChange}
                            onClickDay={handleClicaDia}  
                            formatMonthYear={formataMesAnoCalendar}  
                            prev2Label={null}
                            next2Label={null}                    
                        />               
           
                    </div>
                    <div style={{marginTop: "10px"}}>
                    <Button 
                             onClick={() => fModalEnxaixe()}>
                                Encaixe
                        </Button>
                    </div>

                </div>
                <div className="col-md-10" >
                <br />
                <Table>
                    <thead>
                        <tr>
                            <th scope="col">Hora </th>
                            <th scope="col">Nome</th>
                            <th scope="col">Status</th>
                            <th scope="col">Procedimento</th>
                            <th scope="col">Plano</th>
                            <th scope="col">Celular</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody> 
                              
   
                                {/* RENDERIZAÇAO CONDICIONAL PARA O ENCAIXE E O CLICK  NO DIA,
                                QUANDO TEM ENCAIXE NAO TEM CLICK, AI ELE TRAZ AS MSM INF.S DO CLICK
                                POREM COM ENCAIXE, QUE É APAGADO SE NAO FOR AGENDADO. */}

                        { encaixe && <HorariosAgenda dia={data} codigoMed={codMed} click={atualiza} pEncaixe={encaixe} /> } 
                        { click && <HorariosAgenda dia={data} codigoMed={codMed} click={atualiza} 
                        pEncaixe={encaixe} 
                        /> } 
           
                    </tbody>
               </Table>
                </div>


            </div>

            <br />
                        
            <Modal show={modalMSG}>
                <Modal.Header> 
                    <h3>{MSG}</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="info" onClick={() => setModalMSG(false)}>Ok</Button>
                </Modal.Footer>
            </Modal>  
            <Modal show={modalEncaixe}>
                <Modal.Header> 
                    <h3>Encaixe de Horários</h3>
                </Modal.Header> 
                
                <Modal.Body>
                Horário:
                    <Form.Control 
                        onChange={(e) => setHoraEncaixe(e.target.value)}  
                        type="time"
                        step="2"
                        value={horaEncaixe}
                        />               
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="info" onClick={() => fEncaixe(horaEncaixe) }>Gravar</Button>
                    <Button variant="danger" onClick={() => fModalEnxaixe()}>Cancelar</Button>
                </Modal.Footer>

            </Modal> 
           
         </div>
        
    );
}

