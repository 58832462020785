import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {  Button, Form, Modal } from 'react-bootstrap';
import api from '../conexao.js';
import {useNavigate, Link} from 'react-router-dom';
import '../../App.css';
import logOut from '../logOut';
import './CadastroTexto.css'


function Texto() {

  const nave = useNavigate();
  const {register, handleSubmit,reset} = useForm( );  //REGISTRANDO AS FUNÇÕES DO USEFORM
    
    const [texto, setTexto] = useState([]);  
    const [mostrarModal, setMostrarModal] = useState(false);
    const [textodelete, setTextoDelete] = useState();
    const [nometexto, setNomeTexto] = useState();
    const [MSG, setMSG] = useState();
    const [modalMSG, setModalMSG] = useState(false);
      
    const onSubmit = (data) => {

    api.get('/texto', { 
      params: {           //BUSCA POR PARAMETRO ? NOME
        nome: data.NOME
      }}).then( result => {
      setTexto(result.data) // joga a resposta em texto
      }).catch((error) => {
        setMSG(logOut(error))
                setModalMSG(true)
              })
    }

    function handleCadTexto(){
      nave('/texto/cadtexto');
       
      
    }

    function handleMostrarModal(){
      setMostrarModal(true);

    }

    function handleFecharModal(){
      setMostrarModal(false);
    }    


    async function handleExcluir(cod_delete){
             
        const response = 
        await api.delete('/texto/'+ cod_delete)
        .then(()=>{
            handleFecharModal(false); 
            setMSG('Texto Excluido!');
            setModalMSG(true)
            setTexto([])
            reset()
          })
        .catch((error) => {
                  setMSG(logOut(error))
                  setModalMSG(true)
            })
       
    }

    return (
      <div>
         
         <div className='cadastroTexto-formContainer'>
        <h1><center> Cadastro de Textos </center></h1>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Control 
                  placeholder="Digite nome Texto"
                  type="text"
                  name="NOME"
                  style={{textTransform:"uppercase"}}
                  {...register('NOME')}
                  required  
                  />
                  <br />
                  <Button variant="light" type="submit">
                          Buscar Texto </Button> <span />
                  <Button variant="success"  onClick={() => {handleCadTexto()}}>Novo Texto</Button>
                                           {/* defina type submit para que ele troca a route */}         
            </Form> 
         <br />
    <table className="table">
    <thead>
        <tr>
            <th scope="col">Cod.</th>
            <th scope="col">Nome</th>
            <th scope="col"></th>
        </tr>
       
      </thead>
        <tbody>
          {texto.map(customer => ( // percorre todos o array e joga na tela em formato de tabela
                              <tr key={customer.CODIGO}>
                                  <td>{customer.CODIGO}</td>
                                  <td>{customer.NOME}</td>
                                  <td> <Link to={"/texto/editartexto/" + customer.CODIGO}><Button variant="warning" >Editar</Button></Link>
                                  <span> </span>
                                  <Button variant="danger" onClick={ () => {
                                    setTextoDelete(customer.CODIGO)
                                    setNomeTexto(customer.NOME)
                                    handleMostrarModal()
                                  }}>Excluir</Button></td>
                              </tr>
                          ))}
        </tbody>
        </table>
              
    
            </div>
            <Modal show={mostrarModal} backdrop="static">
              <Modal.Header>
                <h2> Excluir Texto? </h2>
              </Modal.Header>
              <Modal.Body>
                  Excluir Texto: <strong>{nometexto}</strong>
              </Modal.Body>
              <Modal.Footer>
              <Button variant="danger" onClick={() => handleExcluir(textodelete)}>Excluir</Button>
              <Button variant="secondary" onClick={() => handleFecharModal()}>Voltar</Button>
              </Modal.Footer>
              </Modal>
              <Modal show={modalMSG}>
                <Modal.Header> 
                    <h3>{MSG}</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="info" onClick={() => setModalMSG(false)}>Ok</Button>
                </Modal.Footer>
            </Modal> 
      </div>
    );

}

export default Texto; //retornar funcao sempre sem ()