import axios from 'axios';

// const URL = 'https://api.solutionsti.info:443/api';
// const URL = 'http://10.0.0.84:3030/api';
// const URL = 'http://10.0.0.142:3030/api';
const URL = 'https://apisti.ddns.net/api'; //DigitalOcean
// const URL = 'http://localhost:3030/api';



const api = axios.create({baseURL: URL});

////////////////////////////////////////////////

const refreshApi = axios.create({ baseURL: URL});



api.interceptors.request.use( 
    (config) => { 
        const token = localStorage.getItem('token'); // Obtém o token armazenado no localStorage 
        if (token) { // if token exstir
                        config.headers.Authorization = `Bearer ${token}`; // Adiciona o token ao cabeçalho de autorização 
                    } 
                    return config; 
                }, (error) => { return Promise.reject(error); 
    } 
);

//////////////////////////////////////////////

    // FUNCAO PARA FICAR ESPERANDO UM RESPONSE DE 401 especifico para post e roda '/consulta' PARA REFAZE-LA COM O REFRESH TOKIN
    // Intercepta respostas para verificar expiração do token

    api.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        
        const originalRequest = error.config; // Armazena a requisição original

        // if (error.response.status === 401 && !originalRequest._retry) {
        if (error.response.status === 401 && originalRequest.method === 'post' && originalRequest.url.includes('/consulta')) {
              
        originalRequest._retry = true;
        const refreshToken = localStorage.getItem('tokenRefresh'); // Obtém o refresh token armazenado
        // console.log('tokenRefresh: ' + refreshToken) 
        try {
            // Faz a requisição para obter um novo token de acesso
            // console.log('cheguei antes do get /refreshtoken') 
            const response          = await refreshApi.post('/refreshtoken', { token: refreshToken });
            const newToken          = response.data.token;
            const newRefreshToken   = response.data.tokenRefresh;
            // console.log('fiz o get no /refreshToken')

            // Armazena o novo token
            localStorage.setItem('token', newToken);
            localStorage.setItem('tokenRefresh', newRefreshToken);
            // console.log('novo token: '+ newToken)
            // Atualiza o cabeçalho com o novo token e tenta a requisição original novamente
            api.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
            return api(originalRequest);
        } catch (err) {
            // Tratamento de erro caso a atualização do token falhe
            return Promise.reject(err);
        }
        }
        return Promise.reject(error);
    }
    )
        
//////////////////////////////////////////





export default api;