import React, { useState, useEffect, Component }from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Button, Form } from 'react-bootstrap';
import api from '../conexao.js';
import { useNavigate, useParams } from 'react-router-dom';
import '../../App.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState,  convertToRaw, ContentState } from 'draft-js';
import logOut from '../logOut';
import './CadastroTexto.css'

 


export default function EditarTexto(){

    const nave = useNavigate();
    const {id} = useParams();

// EditarTexto.propTypes = {
//     id: PropTypes.number.isRequired
// }

const {register, handleSubmit, setValue} = useForm( );  //REGISTRANDO AS FUNÇÕES DO USEFORM
const [exibirModal, setExibirModal] = useState(false); // const para definir se a modal aparece ou nao
const [textoNome, setTextoNome] = useState();
const [tipoTexto, setTipoTexto] = useState([]);

const [html, setHTML] = useState('');
const [contentBlock] = useState(htmlToDraft(html));
const [contentState] = useState(ContentState.createFromBlockArray(contentBlock.contentBlocks));
const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));

const [textoBanco, setTextoBanco] = useState();
const [modalMSG, setModalMSG] = useState(false);
const [MSG, setMSG] = useState();
const toolBarValues = {
    options: ["inline", "fontSize", "colorPicker", "textAlign"],
    inline: {
      inDropdown: false,
      options: ["bold", "italic", "underline", "strikethrough"]
    }
};


function onEditorStateChange(editorState) {
    setEditorState(editorState)
    setTextoBanco(draftToHtml(convertToRaw(editorState.getCurrentContent())));

 
  };

useEffect( () => {

 api.get('/tipotexto').then(resultTT => {
                                                // LAÇO PARA PREENCHER <SELECT> tipo texto
        setTipoTexto(resultTT.data)        
        
    }) .then(  

    api.get('/texto/',{  params: {           
        codigo: id}} ).then(result => {
            setValue("NOME", result.data.NOME);
            setValue("COD_TIPO_TEXTO", result.data.COD_TIPO_TEXTO);
            // var oO = Buffer.from(result.data.TEXTO).toString('latin1');
            // if (oO.includes('�')) {
            //     setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(Buffer.from(result.data.TEXTO).toString('latin1')))));    
            // } else
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(result.data.TEXTOCONVERTIDO))));
       onEditorStateChange(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(result.data.TEXTOCONVERTIDO))));

    //         setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(Buffer.from(result.data.TEXTO).toString()))));
    //    onEditorStateChange(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(Buffer.from(result.data.TEXTO).toString()))));

            
    })
    ).catch((error) => {
        setMSG(logOut(error))
                setModalMSG(true)
              })                                    
}, [id]);


function handleFecharModal(){
        setExibirModal(false); // inicia faalse
        nave('/texto/texto'); // vai para pagina anterior
       
}

function handleVoltar(){
    nave('/texto/texto');
}



const onSubmit = async (data) => {

            // var texto = Buffer.from(textoBanco,'latin1').toString('latin1')
            // console.log(textoBanco)
          
            const  response = 
            await api.put('/texto/'+ id, {
                        NOME:  data.NOME.toUpperCase(),
                        COD_TIPO_TEXTO: parseInt(data.COD_TIPO_TEXTO),
                        TEXTO: textoBanco
                
                }).then( () =>{
                        setTextoNome(data.NOME)
                        setExibirModal(true)}
                ).catch((error) => {
                    setMSG(logOut(error))
                    setModalMSG(true)
                })
    }    


return (  

    <div >

<div className="cadastroTexto-formContainer">
              <h2 className="cadastroTexto-title">Editar Texto</h2>
              <form onSubmit={handleSubmit(onSubmit)} className='cadastroTexto-form '>
                <div className="cadastroTexto-formGroup">
                  <label className="cadastroTexto-label">Nome:</label>
                  <input className="cadastroTexto-input" type="text" maxLength={45} name="NOME" style={{textTransform:"uppercase"}} required  {...register('NOME')}  />
                </div>
                <div className="cadastroTexto-formGroup">
                    <label className="cadastroTexto-label">Tipo do Texto:</label>
                    <Form.Control  size="sm" as="select" name="COD_TIPO_TEXTO" {...register('COD_TIPO_TEXTO')}>
                            {tipoTexto.map( tipotexto => (
                                <option value={tipotexto.CODIGO}>{tipotexto.DESCRICAO}</option>))}
                    </Form.Control>
                </div>
                <label className="cadastroTexto-label">Texto:</label>
                <div className="overflow-scroll" style={{height:"500px",backgroundColor:'white',width:'100%'}} >
                        <Editor 
                            editorState={editorState}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            onEditorStateChange={onEditorStateChange}
                            placeholder="Digite o Texto"
                            toolbar={toolBarValues}
                        />
                </div>
                <div className="cadastroPaciente-formActions">
                    <Button className="btn btn-primary" type="submit" >Gravar</Button>                
                    <Button type="button" className="btn btn-secondary" onClick={() => {handleVoltar()}}>Cancelar</Button>
                </div> 
            </form>
        </div>

   

        <Modal show={exibirModal} onHide={handleFecharModal} backdrop="static">
                <Modal.Header>
                        <h2> Alteração feita com Sucesso! </h2>
                </Modal.Header>
                <Modal.Body>
                  Texto: <strong>{textoNome}</strong>, Alterado!                  
                </Modal.Body>
                <Modal.Footer>
                        <Button variante="success" onClick={() => handleFecharModal()}> Ok </Button>
                </Modal.Footer>
                
        </Modal>
        <Modal show={modalMSG}>
                <Modal.Header> 
                    <h3>{MSG}</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="info" onClick={() => setModalMSG(false)}>Ok</Button>
                </Modal.Footer>
            </Modal> 

        </div>
 

                )

}



