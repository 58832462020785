import React, { useState }from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Button, Form} from 'react-bootstrap';
import api from '../conexao.js';
// import '../../App.css';
import { useNavigate } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import logOut from '../logOut';
import './CadastroPaciente.css'

export default function CadPaciente(){

        const nave= useNavigate();

const {register, handleSubmit} = useForm( );  //REGISTRANDO AS FUNÇÕES DO USEFORM
const [datanasc, setDataNasc] = useState(null);
const [exibirModal, setExibirModal] = useState(false); // const para definir se a modal aparece ou nao
const [cep, setCEP] = useState();
const [tel, setTEL] = useState();
const [cel, setCEL] = useState();
const [cpf, setCPF] = useState();
const [cpf_conjuge, setCPF_CONJUGE] = useState();
const [modalMSG, setModalMSG] = useState(false);
const [MSG, setMSG] = useState();



function handleFecharModal(){
        setExibirModal(false); // inicia faalse
        nave('/paciente/paciente'); // vai para pagina anterior
}

function transformaData(d){  
        if (d != null) {                   // converte data = new Date() para DD/MM/YYYY 
                var date = new Date(d)                      // converte a data ISO DateTimeStamp(como vem do banco) para DD/MM/YYYY
                date.setHours(0,0,0,0)                      // zera a hora 00:00:00
                return(date.getFullYear()+'/' + (date.getMonth()+1) + '/'+date.getDate())   
        }
    }


function handleVoltar(){
        nave('/paciente/paciente');
}

var cepnum = String;
var telnum = String;
var celnum = String;
var cpfnum = String;
var cpfconjnum = String;


 const onSubmit = async (data) => {
//   console.log(data)
         
        if (cep) {
                cepnum = cep;
                cepnum = cepnum.replace(/[^0-9.]/g, ""); // remove o que nao é numero do CEP
        }
        if  (tel)  {
                telnum = tel;
                telnum = telnum.replace(/[^0-9]+/g, "");
        }
        if (cel) {
                celnum = cel;
                celnum = celnum.replace(/[^0-9]+/g, "");
        }
        if (cpf) {
                cpfnum = cpf;
                cpfnum = cpfnum.replace(/[^0-9]+/g, "");
        }
        if (cpf_conjuge) {
                cpfconjnum = cpf_conjuge;
                cpfconjnum = cpfconjnum.replace(/[^0-9]+/g, "");
        }

        
        
        // const response = api.post('/paciente', {
       const response = await api.post('/paciente', {
                COD_EC: parseInt(data.COD_EC),
                NOME: data.NOME.toUpperCase(),
                SEXO: data.SEXO,
                ENDERECO: data.ENDERECO.toUpperCase(),
                BAIRRO: data.BAIRRO.toUpperCase(),
                CIDADE: data.CIDADE.toUpperCase(),
                CEP: cepnum,
                TEL: telnum,
                CEL: celnum,
                CPF: cpfnum,
                IDENT: data.IDENT.toUpperCase(),
                DATA_NASC: transformaData(datanasc),
                CONJUGUE: data.CONJUGE.toUpperCase(),
                CPF_CONJUGE: cpfconjnum,
                PLANO: data.PLANO.toUpperCase(),
                NATURALIDADE: data.NATURALIDADE.toUpperCase(),
                PROFISSAO: data.PROFISSAO.toUpperCase(),
                INDICADO_POR: data.INDICADOPOR.toUpperCase(),
                OBS: data.OBS
        }).then( () => {setExibirModal(true)})
        .catch((error) => {
                setMSG(logOut(error))
                setModalMSG(true)
            })
       
        
       
}   
        
    return(

                        
                

            <div className="cadastroPaciente-formContainer">
              <h2 className="cadastroPaciente-title">Cadastro do Paciente</h2>
              <form onSubmit={handleSubmit(onSubmit)} className='cadastroPaciente-form '>
                <div className="cadastroPaciente-formGroup">
                  <label className="cadastroPaciente-label">Nome:</label>
                  <input className="cadastroPaciente-input" type="text" name="NOME" 
                  style={{textTransform:"uppercase"}} 
                  required
                  {...register('NOME')}  />
                </div>
                <div className="cadastroPaciente-formGroup3">
                  <label className="cadastroPaciente-label">Sexo:</label>
                  <select defaultValue={"M"} className="cadastroPaciente-input" name="SEXO" {...register('SEXO')}>
                    <option value="M">MASCULINO</option>
                    <option value="F">FEMININO</option>
                  </select>
                </div>
                <div className="cadastroPaciente-formGroup">
                  <label className="cadastroPaciente-label">Endereço:</label>
                  <input className="cadastroPaciente-input" type="text" name="ENDERECO" maxLength={45}
                   {...register('ENDERECO')}
                   style={{textTransform:"uppercase"}}  />
                </div>
                <div className="cadastroPaciente-formGroup">
                  <label className="cadastroPaciente-label">Bairro:</label>
                  <input className="cadastroPaciente-input" type="text" name="BAIRRO" maxLength={45}
                  style={{textTransform:"uppercase"}} 
                  {...register('BAIRRO')}/>
                </div>
                <div className="cadastroPaciente-formGroup">
                  <label className="cadastroPaciente-label">Cidade:</label>
                  <input className="cadastroPaciente-input" type="text" name="CIDADE" maxLength={45}
                  style={{textTransform:"uppercase"}}
                  {...register('CIDADE')}/>
                </div>
                <div className="cadastroPaciente-formGroup3">
                  <label className="cadastroPaciente-label">CEP:</label>
                  {/* <input className="cadastroPaciente-input" type="text" name="cep" {...register('CEP')}/> */}
                      <InputMask  
                          mask="99999-999" 
                          size="8" 
                          name="CEP"  
                          {...register('CEP')} 
                          value={cep}
                          onChange={(e) => setCEP(e.target.value)}  // unica maneira para registrar o cep no State
                          alwaysShowMask={false}
                        />
                </div>
                <div className="cadastroPaciente-formGroup3">
                  <label className="cadastroPaciente-label">Tel.:</label>
                  {/* <input className="cadastroPaciente-input" type="text" name="telefone" {...register('TEL')} /> */}
                         <InputMask
                                 mask="(99)9999-9999"
                                 size="24"
                                 name="TEL"
                                 {...register('TEL')}
                                 value={tel}
                                 onChange={(e) => setTEL(e.target.value)}
                                 alwaysShowMask={false}
                         /> 
                </div>
                <div className="cadastroPaciente-formGroup3">
                  <label className="cadastroPaciente-label">Cel.:</label>
                  {/* <input className="cadastroPaciente-input" type="text" name="celular" {...register('CEL')} /> */}
                  <InputMask
                                mask="(99)99999-9999"
                                size="24"
                                name="CEL"
                                {...register('CEL')}
                                value={cel}
                                onChange={(e) => setCEL(e.target.value)}
                                alwaysShowMask={false}
                        />
                </div>
                <div className="cadastroPaciente-formGroup3">
                  <label className="cadastroPaciente-label">CPF:</label>
                  {/* <input className="cadastroPaciente-input" type="text" name="cpf" {...register('CPF')}/> */}
                  <InputMask
                                mask="999.999.999-99"
                                size="24"
                                name="CPF"
                                {...register('CPF')}
                                value={cpf}
                                onChange={(e) => setCPF(e.target.value)}
                                alwaysShowMask={false}
                        />
                </div>
                <div className="cadastroPaciente-formGroup3">
                  <label className="cadastroPaciente-label">RG:</label>
                  <input className="cadastroPaciente-input" maxLength="15" type="text" name="IDENT" 
                  style={{textTransform:"uppercase"}}
                  {...register('IDENT')}/>
                </div>
                <div className="cadastroPaciente-formGroup3">
                  <label className="cadastroPaciente-label">Data Nascimento:</label>
                  {/* <input className="cadastroPaciente-input" type="date" name="dataNascimento" {...register('DATA_NASC')}/> */}
                  <DatePicker                         
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Selecione a data"
                        selected={datanasc}
                        onChange={setDataNasc}                         
                        name="DATA_NASC"
                        />
                </div>
                <div className="cadastroPaciente-formGroup3">
                  <label className="cadastroPaciente-label">Estado Civil:</label>
                  <select className="cadastroPaciente-input" name="COD_EC" {...register('COD_EC')}>
                             <option value="2">CASADO(A)</option>
                             <option value="4">DIVORCIADO(A)</option>
                             <option value="6" selected="selected">NENHUM</option>
                             <option value="5">SEPARADO(A)</option>
                             <option value="1">SOLTEIRO(A)</option>
                             <option value="3">VIÚVO(A)</option>
                  </select>
                </div>
                <div className="cadastroPaciente-formGroup3">
                  <label className="cadastroPaciente-label">Conjuge:</label>
                  <input className="cadastroPaciente-input" maxLength={45} type="text" name="CONJUGE" 
                  style={{textTransform:"uppercase"}}
                  {...register('CONJUGE')}/>
                </div>
                <div className="cadastroPaciente-formGroup3">
                  <label className="cadastroPaciente-label">CPF Conjuge:</label>
                  {/* <input className="cadastroPaciente-input" type="text" name="CPF_CONJUGE" {...register('CPF_CONJUGE')} /> */}
                  <InputMask
                                mask="999.999.999-99"
                                size="24"
                                name="CPF_CONJUGE"
                                {...register('CPF_CONJUGE')}
                                value={cpf_conjuge}
                                onChange={(e) => setCPF_CONJUGE(e.target.value)}
                                alwaysShowMask={false}
                        />
                </div>
                <div className="cadastroPaciente-formGroup">
                  <label className="cadastroPaciente-label">Plano:</label>
                  <input className="cadastroPaciente-input" type="text" name="PLANO" maxLength={45} 
                  style={{textTransform:"uppercase"}}
                  {...register('PLANO')}/>
                </div>
                <div className="cadastroPaciente-formGroup">
                  <label className="cadastroPaciente-label">Natualidade:</label>
                  <input className="cadastroPaciente-input" type="text" name="NATURALIDADE" maxLength={45}
                  style={{textTransform:"uppercase"}}
                  {...register('NATURALIDADE')}/>
                </div>
                <div className="cadastroPaciente-formGroup">
                  <label className="cadastroPaciente-label">Profissão:</label>
                  <input className="cadastroPaciente-input" type="text" name="PROFISSAO" maxLength={45}
                  {...register('PROFISSAO')}
                  style={{textTransform:"uppercase"}}/>
                </div>
                <div className="cadastroPaciente-formGroup">
                  <label className="cadastroPaciente-label">Indicado por:</label>
                  <input className="cadastroPaciente-input" type="text" name="INDICADOPOR" maxLength={45}
                  {...register('INDICADOPOR')}
                  style={{textTransform:"uppercase"}}/>
                </div>
                <div className="cadastroPaciente-formGroup">
                  <label className="cadastroPaciente-label">Observação:</label>
                  <textarea className="cadastroPaciente-input" name="OBS" maxLength={120}
                  {...register('OBS')}
                  style={{textTransform:"uppercase"}}></textarea>
                </div>
                <div className="cadastroPaciente-formActions">
                  <Button className="btn-primary"  type="submit">Gravar</Button>
                  <Button className="btn-secondary" 
                  onClick={() => {handleVoltar()}}  type="submit">Cancelar</Button>
                </div>
              </form>

              <Modal show={exibirModal} onHide={handleFecharModal} backdrop="static">
                <Modal.Header>
                        <h2> Cadastro Paciente </h2>
                </Modal.Header>
                <Modal.Body>
                        Paciente Gravado!
                </Modal.Body>
                <Modal.Footer>
                        <Button variante="success" onClick={() => handleFecharModal()}> Ok </Button>
                </Modal.Footer>
        </Modal>
        <Modal show={modalMSG}>
                <Modal.Header> 
                    <h3>{MSG}</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="info" onClick={() => setModalMSG(false)}>Ok</Button>
                </Modal.Footer>
            </Modal> 

            </div>
        
    )
};


