import React from 'react';
import {useState, useEffect} from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import api from '../conexao.js';
import { format } from 'date-fns';
import logOut from '../logOut';
import DatePicker from 'react-datepicker';
import './ConfAgenda.css'
 export default function ConfAgenda(){

    const [codMed, setCodMed] = useState(1) //começa com 1 para abrir junto com useEffect
    const [medicos, setMedicos] = useState([])
    const [diaAtend, setDiaAtend] = useState('SEGUNDA')
    const [horaInicio, setHoraInicio] = useState()
    const [horaTermino, setHoraTermino] = useState()
    const [horaInterInicio, setHoraInterInicio] =useState();
    const [horaInterTermino, setHoraInterTermino] =useState();
    const [diaAvulso, setDiaAvulso] = useState();
    const [dia,setDia] = useState()
    const [diaInteiro, setDiaInteiro] = useState();
    const [duracao, setDuracao] = useState();
    const [confAgenda, setConfAgenda] = useState([]);
    let checkbox = document.getElementById('dia avulso');
    const [chk, setCHK] = useState(true);
    const [modalMSG, setModalMSG] = useState(false);
    const [MSG, setMSG] = useState();

    function transformaDataBanco(d){                     
        var date = new Date(d)                      
        date.setHours(0,0,0,0)                      // zera a hora 00:00:00
        return(date.getFullYear()+'/' + (date.getMonth()+1) + '/'+date.getDate())   
    }    

    function transformaDataBR(d){
        var date = new Date(d)                      // converte a data ISO DateTimeStamp(como vem do banco) para DD/MM/YYYY
        date.setHours(0,0,0,0)                      // zera a hora 00:00:00
        return(date.getDate()+'/' + (date.getMonth()+1) + '/'+date.getFullYear())      
    }

    useEffect( () => {   

        async   function buscaMedicos() {
       
             await  api.get('/medico',{params:{
                nome: '%'
             }}).then(listamedicos => {   
                   setMedicos(listamedicos.data)
                   setCodMed(listamedicos.data[0].CODIGO)
       
               }).catch((error) => {
                setMSG(logOut(error))
                setModalMSG(true) 
            }) 
           }
           
           buscaMedicos();          
           buscaConfAgenda(codMed)

    },[]);  


    async function buscaConfAgenda(codMed){
        setCodMed(codMed)
        // console.log(confAgenda)
        await api.get('/horarioagenda',{
        params:{
            codMed: codMed
        }}
    
        ).then(lconfAgenda => {
            
                setConfAgenda(lconfAgenda.data) 
                

        }).catch((error) => {
            setMSG(logOut(error))
            setModalMSG(true)  
        })


    }

    function confereDia (diaExtenso,dia){
       
        if (dia) {
        handleGrava()
       } else {
                const verifDiaExiste = confAgenda.filter( (teste) => teste.DIA_EXTENSO 
                === diaExtenso && teste.DATA_VIGOR === null );
                
                if (verifDiaExiste.length == 0) {
                    handleGrava()
                } else {
                    
                    setMSG('Já Existe esse dia da Semana Cadastrado!')
                    setModalMSG(true)
                }
        }
    }
    
    async function handleGrava(){

            //DAR UM GET, BUSCAR SE EXISTE O DIA COMUM JÁ EXISTE, E PERGUNTAR 
            //SE DESEJA SUBSTITUIR;
         

            // CHAMAR OUTRA FUNCÃO PARA FAZER A VERIFICAÇÃO ANTES DA FUNÇÃO GRAVAR.
            if (dia) {
                        diaBanco =transformaDataBanco(dia)}
                else {
                        var diaBanco = null}

            api.post('/horarioagenda', {
                // codigo: 1,
                cod_medico: parseInt(codMed),
                hora_ini: horaInicio,
                hora_ter: horaTermino,
                hora_int_ini: horaInterInicio,
                hora_int_ter: horaInterTermino,
                tempo_consulta: parseInt(duracao),
                data_vigor : diaBanco,
                dia : diaInteiro,
                dia_extenso : diaAtend

            })
            .then( () => {
                buscaConfAgenda(codMed)
            })
            .catch((error) => {
                setMSG(logOut(error))
                setModalMSG(true) 
            })
         
            setMSG('Gravado')
            setModalMSG(true)

    }

    async function handleExcluir(cod_delete){
        const response = await api.delete('/horarioagenda/'+ cod_delete)
        .then(()=> {
            buscaConfAgenda(codMed)
            setMSG('Horário Excluido!')
            setModalMSG(true)
        })
        .catch((error) => {
            setMSG(logOut(error))
            setModalMSG(true)
        })

    }

    function desativaDarePiker(){
        if (chk) {
                setCHK(false)
        } else {setCHK(true) 
                setDia()
            } 
    }
        
    function SetaDiaAtend(d){

        setDiaAtend(d);
        
        if (d === 'SEGUNDA'){setDiaInteiro(1)};
        if (d === 'TERCA'){setDiaInteiro(2)};
        if (d === 'QUARTA'){setDiaInteiro(3)};
        if (d === 'QUINTA'){setDiaInteiro(4)};
        if (d === 'SEXTA'){setDiaInteiro(5)};
        if (d === 'SABADO'){setDiaInteiro(6)};    
        
    }


    return (

        <div>
             <div> 
            
            <div className="confAgenda-formContainer">
            <h2 className="confAgenda-title">Cadastro de Medicamentos</h2>
            <form  className='confAgenda-form '>
                <div className="confAgenda-formGroup3">
                    <label className="confAgenda-label">Selecione o Médico:</label>
                    <Form.Control  size="sm" as="select" value={codMed} onChange={e => buscaConfAgenda(e.target.value)} >
                                    <option disabled selected value>Selecione o Médico</option>
                                        {medicos.map( med => (
                                            
                                            <option value={med.CODIGO}>{med.NOME}</option>
                                        ))}
                                        {medicos.CODIGO}
                    </Form.Control>
                </div>    
                    <div className="confAgenda-formGroup3">
                        <label className="confAgenda-label">Selecione o Médico:</label>
                        <Form.Control size="sm" as="select" name="DIA" onChange={e => SetaDiaAtend(e.target.value)}>
                            <option disabled selected value>Dia da Semana</option>
                            <option value="SEGUNDA">SEGUNDA</option>
                            <option value="TERCA">TERÇA</option>                
                            <option value="QUARTA">QUARTA</option>                
                            <option value="QUINTA">QUINTA</option>                
                            <option value="SEXTA">SEXTA</option>                
                            <option value="SABADO">SÁBADO</option>                
                        </Form.Control> 
                    </div>  
                    <br />
                    <div className="confAgenda-formGroup3">
                        <label className="confAgenda-label">Hora Início:</label>
                        <input type="time"
                                name="horainicio"
                                value={horaInicio}
                                onChange={(e) => setHoraInicio(e.target.value)}
                                step="2"
                        ></input>
                    </div>
                    <div className="confAgenda-formGroup3">
                        <label className="confAgenda-label">Hora Fim:</label>
                        <input type="time"
                                name="horatermino"
                                value={horaTermino}
                                onChange={(e) => setHoraTermino(e.target.value)}
                                step="2"
                        ></input>
                    </div>
                    <div className="confAgenda-formGroup3">
                        <label className="confAgenda-label">Hora Início Intervalo:</label>
                        <input type="time"
                                name="horaInterInicio"
                                value={horaInterInicio}
                                onChange={(e) => setHoraInterInicio(e.target.value)}
                                step="2"
                        ></input>
                    </div>
                    <div className="confAgenda-formGroup3">
                        <label className="confAgenda-label">Hora Fim Intervalo:</label>
                        <input type="time"
                              name="horaInterTermino"
                              value={horaInterTermino}
                              onChange={(e) => setHoraInterTermino(e.target.value)}
                              step="2"
                        ></input>
                    </div>
                    <div className="confAgenda-formGroup3">
                        <label className="confAgenda-label">Duração(Minutos):</label>
                        <Form.Control 
                            size="sm" 
                            type="text" 
                            name="Duracao" 
                            value={duracao} 
                            onChange={(e) => {setDuracao(e.target.value)} } />
                    </div>
                    <div className="confAgenda-formGroup3">
                        <label className="confAgenda-label">Duração(Minutos):</label>
                            <Form.Check 
                                type="switch"
                                id="dia avulso"
                                label="Dia Avulso"
                                value="1"
                                onChange={(e) => desativaDarePiker()}
                            />
                    </div>
                    <div className="confAgenda-formGroup3">
                        <label className="confAgenda-label">Dia:</label>
                            <DatePicker                         
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Selecione a data"
                            selected={dia}
                            onChange={setDia}                         
                            name="DIA"
                            disabled={chk}
                            /> 
                            <br />
                            <br />
                            {/* <div className='className="cadastroMedicamento-formActions'> */}
                        <Button className="btn btn-primary"  onClick={() => {confereDia(diaAtend,dia)}}>
                            Gravar
                        </Button>
                    {/* </div> */}
                    </div>
                    
                    
                    
            
            
            
            
            
            
            </form>
            <br />

                    {/* <Form>
                        <div className="form-row">
                            <div className="form-group col-md-4">
                                    Selecione o Médico:
                                    <Form.Control  size="sm" as="select" value={codMed} onChange={e => buscaConfAgenda(e.target.value)} >
                                    <option disabled selected value>Selecione o Médico</option>
                                        {medicos.map( med => (
                                            
                                            <option value={med.CODIGO}>{med.NOME}</option>
                                        ))}
                                        {medicos.CODIGO}
                                    </Form.Control>
                                </div>
                                
                                <div className="form-group col-md-4">
                                            Dia Atendimento:
                                            <Form.Control size="sm" as="select" name="DIA" 
                                            // value={diaAtend}
                                             onChange={e => SetaDiaAtend(e.target.value)}>
                                                <option disabled selected value>Dia da Semana</option>
                                                <option value="SEGUNDA">SEGUNDA</option>
                                                <option value="TERCA">TERÇA</option>                
                                                <option value="QUARTA">QUARTA</option>                
                                                <option value="QUINTA">QUINTA</option>                
                                                <option value="SEXTA">SEXTA</option>                
                                                <option value="SABADO">SÁBADO</option>                
                                            </Form.Control>
                                    </div>          
                                    <div className="form-group col-md-2">
                                        Início:
                                        <br />
                                        <input type="time"
                                                name="horainicio"
                                                value={horaInicio}
                                                onChange={(e) => setHoraInicio(e.target.value)}
                                                step="2"
                                        ></input>

                                     </div>
                                    <div className="form-group col-md-2">
                                        Término:
                                        <br />
                                        <input type="time"
                                                name="horatermino"
                                                value={horaTermino}
                                                onChange={(e) => setHoraTermino(e.target.value)}
                                                step="2"
                                        ></input>
 
                                    </div>
                             
                                    <div className="form-group col-md-2">
                                        Intervalo Início:
                                        <br />
                                        <input type="time"
                                                name="horaInterInicio"
                                                value={horaInterInicio}
                                                onChange={(e) => setHoraInterInicio(e.target.value)}
                                                step="2"
                                        ></input>
       
                                    </div>
                                    <div className="form-group col-md-2">
                                        Intervalo Término:
                                        <br />
                                        <input type="time"
                                                name="horaInterTermino"
                                                value={horaInterTermino}
                                                onChange={(e) => setHoraInterTermino(e.target.value)}
                                                step="2"
                                        ></input>

                                    </div>
                                    <div className="form-group col-md-2">
                                            Duração(Minutos):
                                            <Form.Control size="sm" type="text" name="Duracao" value={duracao} onChange={(e) => {setDuracao(e.target.value)} } />
                                    </div> 
                                    <spam></spam>
                                    <div className="form-group col-md-2">
                                        <Form.Check 
                                            type="switch"
                                            id="dia avulso"
                                            label="Dia Avulso"
                                            value="1"
                                            onChange={(e) => desativaDarePiker()}
                                        /> 
                                    </div> 
                                    <div className="form-group col-md-4">
                                            Dia:
                                            <DatePicker                         
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="Selecione a data"
                                            selected={dia}
                                            onChange={setDia}                         
                                            name="DIA"
                                            disabled={chk}
                                            /> 
                                    </div>                  
                                    <div className="form-group col-md-10">
                                        <Button 
                                            className="btn btn-primary" 
                                            
                                            onClick={() => {confereDia(diaAtend,dia)}}
                                            >
                                        Gravar
                                        </Button>
                                    </div>
                                     


                            </div>  
                    </Form> */}

    <table className="table" style={{fontSize: "85%"}}>
    <thead>
        <tr>
            <th scope="col">Dia da Semana</th>
            <th scope="col">Duração</th>
            <th scope="col">Hora Início</th>
            <th scope="col">Hora Termino</th>
            <th scope="col">Hora Intervalo Início</th>
            <th scope="col">Hora Intervalo Termino</th>
            <th scope="col">Dia</th>
            <th scope="col"></th>
        </tr>
       
      </thead>
        <tbody>
        { 
        

            
            

          confAgenda.map(conf => ( // percorre todos o array e joga na tela em formato de tabela
                                        
                              <tr key={conf.codigo}>
                                  <td>{conf.dia_extenso}</td>
                                  <td>{conf.tempo_consulta}</td>
                                  <td>{conf.hora_ini}</td>
                                  <td>{conf.hora_ter}</td>
                                  <td>{conf.hora_int_ini}</td>
                                  <td>{conf.hora_int_ter}</td>
                                  <td> {conf.data_vigor &&
                                      transformaDataBR(conf.data_vigor)}</td>
                                    {/* //   conf.data_vigor.split('-').reverse().join('/')}</td>                         */}
                                  <td> 
                                  
                                  <Button variant="danger" onClick={ () => {
                                        handleExcluir(conf.codigo)
                                  }}>Excluir</Button></td>
                              </tr>
                          ))}
        </tbody>
        </table>  


    </div>
                                   
            </div>
            <Modal show={modalMSG}>
                <Modal.Header> 
                    <h3>{MSG}</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="info" onClick={() => setModalMSG(false)}>Ok</Button>
                </Modal.Footer>
            </Modal>  
        </div>
        
    );
 }