import React, {useEffect, useState} from 'react';
import api from '../conexao.js';
import { useForm } from 'react-hook-form';
import {Form, Button, Spinner,Modal} from 'react-bootstrap';
import './whatsapp.css';


export default function WhatsApp() {

    const {register, handleSubmit} = useForm( );  //REGISTRANDO AS FUNÇÕES DO USEFORM
    const [cel, setCEL] = useState();
    const [status, setStatus] = useState(true);
    const [qrCode, setQRCode] = useState();
    const [statusDevice, setStatusDevice] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [bancoWP, setBancoWP] = useState();
    var authKey =  "woSDS1DYcdrfdVFTwKCBk1bjPDxR4o6EdsLs6io7er6W5UAbHV";


    let tokin = localStorage.getItem('token')  //LE O TOKEN QUE FOI GRAVADO NO LOGIN
    // let banco = localStorage.getItem('banco')  // le o banco no tokem
    var payload = tokin.split('.')[1];
    var decodedPayload = JSON.parse(window.atob(payload));
    var medico = decodedPayload.param2
    var celular

    async function getQRCode() { // busca qrCode na menuia de acordo com a authkey e mesaage
        await api.post('https://www.chatbot.menuia.com/api/developer', { 
                authkey: authKey,
                message: medico,
                conecteQR: 'true'
        }).then((result) => {
            
            setQRCode(result.data.message.qr)
            setModal(true)        
           verifyDevice2()
        
        }).catch((error) => { alert(error.message)})

    }   


useEffect(()=>{
    
    async function verifyDevice() {
        await api.post('https://www.chatbot.menuia.com/api/developer', { 
                authkey: authKey,
                message: medico,
                checkDispositivo: 'true'
        }).then((result) => {
            // celular = result.data.dados.phone;
            if (result.data.message === 'Dispositivo desconectado'){ 
                setLoading(true) }  
            if (result.data.message === 'Dispositivo Conectado'){ 
                // console.log(result.data.dados.phone)
                celular = result.data.dados.phone
                setCEL(result.data.dados.phone)
                setLoading(false)
                
                // console.log(result.data.dados.phone)
                // buscaWhatsapp(result.data.dados.phone)    
            }  
        }).catch((error) => { if (error) {
            setLoading(true)
        }})    
    }

        verifyDevice()   

} ,[loading])

async function grava(){
    var dados;
    await api.get('/whatsapp',{params:{
        numero: celular
    }}).then((result) => { dados = result.data
                            // celular = result.data.numero;
    })
    if (!dados) {
        // console.log(celular)
        await api.post('/whatsapp',
        {
            numero: celular,
            descricao: medico,
            authkey: authKey,       //SÓ ACEITA O PRIMEIRO CELULAR QUE LEU O QR-CODE
            appkey: medico,
            status: 'ativo'
        }).then(() => {alert('WhatsApp Associado!')
                        return(true)})
            .catch((error) => {alert('erro gravando: '+ error.message)
                                return(false)})
            
    } else {
            if (dados.numero === celular) {
                    return(true)
                } else {
                        return(false)
                    }
            }
    
}
    

async function verifyDevice2(){
    
    var resultado;  

    let intervalo = setInterval(   
    async ()=> { await api.post('https://www.chatbot.menuia.com/api/developer', { 
                authkey: authKey,
                message: medico,
                checkDispositivo: 'true'
        }).then((result) => {
                    resultado = '';

                    if (result.data.message === 'Dispositivo desconectado'){ resultado = false}
                    if (result.data.message === 'Dispositivo Conectado'){  resultado = true}  
                    if (resultado === true) {   if (  grava())  { 
                                                                celular = result.data.dados.phone;
                                                                // console.log(result.data.dados.phone)
                                                                setModal(false)
                                                                clearInterval(intervalo)
                                                                setLoading(false)
                                                } else {
                                                    alert(celular + ' Não é celular válido')  //SÓ ACEITA O PRIMEIRO CELULAR QUE LEU O QR-CODE
                                                }
                                                
                                            }
            }).catch((error) => {alert(error.message)})
    }
    ,10000);

}        
        


return (
    <>
    
    <div className='whatsapp-formContainer'>
            <br />
            <div className="container">
                <h3><font color='green'>WhatsApp</font></h3>

                {loading ? 
                    <>
                        <Button onClick={() => getQRCode()}>Obter QRCode</Button>
                        
                    </> :
                    <>{ cel ?
                        <>
                            <h4>Conectado!</h4>   
                            <h4>Celular: <h5>{cel}</h5> </h4>
                            {/* AGORA GRAVAR OS DADOS DA REQUISIÇAO NO BANCO. PARA BUSCAR QUANDO NECESSARIO E ASSOCIAR A CADA USUARIO. */}
                        </>
                        : <Spinner /> }
                     </>
                }

            </div>

    </div>

    <Modal show={modal} size="md">
        <Modal.Header>
            <center>
                <h3>Sincronize com WhatsApp de seu Celular</h3>
            </center> 
        </Modal.Header>
        <Modal.Body>
            <center>
                <h4>QR-CODE</h4>
                <img src={qrCode} alt="QR-CODE" />
            </center>
            
        </Modal.Body>
        <Modal.Footer>
        <Button onClick={()=> {setModal(false)}} >Fechar</Button>
        <Spinner></Spinner>Aguarde...   
        </Modal.Footer>
    </Modal>
    
    </>
)

}
