import React, { useState, useEffect }from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Button, Form, Jumbotron } from 'react-bootstrap';
import api from '../conexao.js';
import { useNavigate, useParams } from 'react-router-dom';

import '../../App.css';
import logOut from '../logOut';
import './CadastroMedicamento.css'



export default function EditarMedicamento(){

    const nave = useNavigate();
    const {id} = useParams()
// EditarMedicamento.propTypes = {
//     id: PropTypes.number.isRequired
// }

const {register, handleSubmit, setValue} = useForm( );  //REGISTRANDO AS FUNÇÕES DO USEFORM

const [exibirModal, setExibirModal] = useState(false); // const para definir se a modal aparece ou nao
const [medicNome, setMedicNome] = useState();
const [MSG, setMSG] = useState();
const [modalMSG, setModalMSG] = useState(false);


useEffect( () => {
    api.get('/medicamento/', {  params: {           
        codigo: id}}).then(result => {
           setValue("NOME", result.data.NOME);
           setValue("DOSE", result.data.DOSE);
           setValue("QUANTIDADE", result.data.QUANTIDADE);

    }).catch((error) => {
        setMSG(logOut(error))
                setModalMSG(true)
              })  
}, [id]);



function handleFecharModal(){
        setExibirModal(false); // inicia faalse
        nave('/medicamento/medicamento'); // vai para pagina anterior
   //     window.location.reload(); //da um load na pagina
}

function handleVoltar(){
    nave('/medicamento/medicamento');
    //    window.location.reload(); 
}



 const onSubmit = async (data) => {

        const response = 
        await api.put('/medicamento/'+ id, {
                NOME:  data.NOME.toUpperCase(),
                DOSE:  data.DOSE.toUpperCase(),
                // QUANTIDADE:  data.QUANTIDADE.toUpperCase()

        }).then(() => {
        setMedicNome(data.NOME);
        setExibirModal(true);})
        .catch((error) => {
            setMSG(logOut(error))
                    setModalMSG(true)
                  })  
    }

        return (

            
        <div >

            <div className="cadastroMedicamento-formContainer">
                <h2 className="cadastroMedicamento-title">Editar Medicamento</h2>
                <form onSubmit={handleSubmit(onSubmit)} className='cadastroMedicamento-form '>
                    <div className="cadastroMedicamento-formGroup">
                        <label className="cadastroMedicamento-label">Nome:</label>
                        <input className="cadastroMedicamento-input" type="text" name="NOME" style={{textTransform:"uppercase"}} required {...register('NOME')} maxLength={45}  />
                    </div>                 


                    <div className="cadastroMedicamento-formGroup">
                        <label className="cadastroMedicamento-label">Dose:</label>
                        <textarea className="cadastroMedicamento-input" name="DOSE" {...register('DOSE')} style={{textTransform:"uppercase"}} rows="5"></textarea>
                    </div>
                    <div className="cadastroMedicamento-formActions">
                        <Button className="btn-primary"  type="submit">Gravar</Button>
                        <Button className="btn-secondary" onClick={() => {handleVoltar()}}  type="submit">Cancelar</Button>
                    </div>

                </form>
            </div>

        <Modal show={exibirModal} onHide={handleFecharModal} backdrop="static">
                <Modal.Header>
                        <h2> Alteração feita com Sucesso! </h2>
                </Modal.Header>
                <Modal.Body>
                  Paciente: <strong>{medicNome}</strong>, Alterado!                  
                </Modal.Body>
                <Modal.Footer>
                        <Button variante="success" onClick={() => handleFecharModal()}> Ok </Button>
                </Modal.Footer>
                
        </Modal>
        <Modal show={modalMSG}>
                <Modal.Header> 
                    <h3>{MSG}</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="info" onClick={() => setModalMSG(false)}>Ok</Button>
                </Modal.Footer>
            </Modal> 
        </div>
 

                )

}



