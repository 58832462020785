import React, { useState,useEffect }from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import api from '../conexao.js';
import logOut from '../logOut';
import { useNavigate} from 'react-router-dom';

import AWS from 'aws-sdk';



function Clinica()
{
    const nave= useNavigate();
    const {register, handleSubmit, setValue} = useForm( );  //REGISTRANDO AS FUNÇÕES DO USEFORM
    const [modalMSG, setModalMSG] = useState(false);
    const [MSG, setMSG] = useState();
    const [exibirModal, setExibirModal] = useState(false); // const para definir se a modal aparece ou nao
    const [tel, setTEL] = useState();
    const [cabecalho, setCabecalho] = useState();
    const [rodape, setRodape] = useState();
    const [temCabecalho, setTemCabecalho] = useState();
    const [temRodape, setTemRodape] = useState();
    useEffect( () => {
    api.get('/clinica').then(result => {
            setValue("NOME", result.data[0].NOME);
            setValue("ENDERECO", result.data[0].ENDERECO);
            setValue("BAIRRO", result.data[0].BAIRRO);
            setValue("CIDADE", result.data[0].CIDADE);
            setValue("UF", result.data[0].ESTADO);

            if (result.data[0].CABECALHO) { setTemCabecalho(1); setCabecalho(result.data[0].CABECALHO)} 
            // setValue("CABECALHO", result.data[0].CABECALHO);
            if (result.data[0].RODAPE) { setTemRodape(1); setRodape(result.data[0].RODAPE)} 
            // setValue("RODAPE", result.data[0].RODAPE);
        //    setValue("LOGO", result.data.LOGO);

            if (result.data[0].TELEFONE) {  
                setTEL(result.data[0].TELEFONE.toString());
                setValue("TELEFONE", tel);
            }

    }) .catch(        (error) => {
        setMSG(logOut(error))
        setModalMSG(true)
    })   ;
},[]);  




    function handleFecharModal(){
        setExibirModal(false); // inicia faalse
       // nave('/medico/medico'); // vai para pagina anterior
}
var telnum = String;
const onSubmit = (data) => {
         


        if  (tel)  {
                telnum = tel;
                telnum = telnum.replace(/[^0-9]+/g, "");

        }
        function grava() { 
            
            var cab,rod
            // if (temCabecalho = 1) {cab = cabecalho.name}
            // if (temRodape = 1) {rod = rodape.name} 

            if (cabecalho.name) {cab = cabecalho.name} else {cab = cabecalho}
            if (rodape.name)    {rod = rodape.name }   else {rod = rodape}
            api.put('/clinica', {
                NOME: data.NOME.toUpperCase(),
                ENDERECO: data.ENDERECO.toUpperCase(),
                BAIRRO: data.BAIRRO.toUpperCase(),
                CIDADE: data.CIDADE.toUpperCase(),
                TELEFONE: telnum,
                ESTADO: data.UF.toUpperCase(),
                CABECALHO: cab,
                RODAPE: rod,
                //LOGO: parseInt(1)

        })
        .catch( 
                (error) => {
                setMSG(logOut(error))
                setModalMSG(true)
            }
            )
            .then(setExibirModal(true)) 
        }
        console.log(cabecalho)
        console.log(rodape)
        grava();
       if (cabecalho.name) {uploadCabecalho()};
        if (rodape.name) {uploadRodape()};
    }

    
    const uploadCabecalho = async () => {
        const S3_BUCKET = "imgmedsolutions";
        const REGION = "sa-east-1";
        AWS.config.update({
          accessKeyId: "AKIAY3BB4HQM7BZWG7OA",
          secretAccessKey: "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",
        });
        const s3 = new AWS.S3({
          params: { Bucket: S3_BUCKET },
          region: REGION,
        });
    
        const params = {
          Bucket: S3_BUCKET,
          Key: cabecalho.name,
          Body: cabecalho
        };
    
        var upload = s3
          .putObject(params)
          .on("httpUploadProgress", (evt) => {
            console.log(
              "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
            );
          })
          .promise();
    
        await upload.then((err, data) => {
          console.log(err);
          alert("File uploaded successfully.");
        });
      };


    const uploadRodape = async () => {
        const S3_BUCKET = "imgmedsolutions";
        const REGION = "sa-east-1";
        AWS.config.update({
          accessKeyId: "AKIAY3BB4HQM7BZWG7OA",
          secretAccessKey: "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",
        });
        const s3 = new AWS.S3({
          params: { Bucket: S3_BUCKET },
          region: REGION,
        });
    
        const params = {
          Bucket: S3_BUCKET,
          Key: rodape.name,
          Body: rodape
        };
    
        var upload = s3
          .putObject(params)
          .on("httpUploadProgress", (evt) => {
            console.log(
              "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
            );
          })
          .promise();
    
        await upload.then((err, data) => {
          console.log(err);
          alert("File uploaded successfully.");
        });
      };

    function funcCabecalho(e) {
        const cabeca = e.target.files[0];
        setCabecalho(cabeca);
    }

    function funcRodape(e) {
        const roda = e.target.files[0];
        setRodape(roda);
    }

    return(
        <div className="w-90 p-3 mx-auto">
            <div className='Jumbotron'>
                <center> <h1> Cadastro Clínica </h1> </center>
               <br />
            <div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-90 p-3 mx-auto">
             <div className="form-row">
                <div className="form-group col-md-6" >
                        Nome:
                        <Form.Control style={{textTransform:"uppercase"}}
                        size="sm" type="text" name="NOME"  {...register('NOME')} 
                        />
                </div>
   
           
            </div>
            <div className="form-row">
            <div className="form-group col-md-6">
                        Endereço:
                        <Form.Control style={{textTransform:"uppercase"}} maxLength={45} size="sm" type="text" name="ENDERECO"  {...register('ENDERECO')}/>
                </div> 
                <div className="form-group col-md-6">
                        Bairro:
                        <Form.Control style={{textTransform:"uppercase"}} maxLength={45} size="sm" type="text" name="BAIRRO" {...register('BAIRRO')}/>
                </div>   
            </div>

            <div className="form-row">
            <div className="form-group col-md-6">
                        Cidade:
                        <Form.Control style={{textTransform:"uppercase"}} maxLength={45} size="sm" type="text" name="CIDADE" {...register('CIDADE')}/>
                </div> 

                <div className="form-group col-md-2">
                UF
                    <Form.Control style={{textTransform:"uppercase"}} 
                    type="text" 
                    size="sm" 
                    maxLength={2}
                    name="UF" 
                    {...register('UF')} 
                    required
                    />
                </div>
            </div>


            <div className="form-row">
            <div className="form-group col-md-3">
                        Tel.:
                        <br />
                        <InputMask
                                mask="(99)9999-9999"
                                size="24"
                                name="TELEFONE"
                                {...register('TELEFONE')}
                                value={tel}
                                onChange={(e) => setTEL(e.target.value)}
                                alwaysShowMask={false}
                        />
                </div> 
                <br/>
                <div className="form-group col-md-4">
                Cabeçalho
                    {/* <Form.Control style={{textTransform:"uppercase"}} 
                    type="text" 
                    size="sm" 
                    maxLength={15}
                    name="CABECALHO" 
                    {...register('CABECALHO')} 
                    required
                    /> */}
                    {temCabecalho && <p style={{color:"green"}}>O Cabeçalho já foi selecionado!</p>}
                    <Form.Control  
                    type="file" name="cabecalho" style={{color:"transparent", width:"142px"}} 
                    onChange={e => funcCabecalho(e)} 
                    
                    //  {...register('CABECALHO')} 
                    // required
                    />
                </div>
                <br/>
                <div className="form-group col-md-4">
                Roda-pé
                    {/* <Form.Control style={{textTransform:"uppercase"}} 
                    type="text" 
                    size="sm" 
                    maxLength={15}
                    name="RODAPE" 
                    {...register('RODAPE')} 
                    required
                    /> */}
                    {temRodape && <p style={{color:"green"}}>O Roda-pé já foi selecionado!</p>}
                    <Form.Control
                    type="file" name="rodape" style={{color:"transparent", width:"142px"}} 
                    onChange={e => funcRodape(e)}
                    
                    //  {...register('RODAPE')} 
                    // required
                    />
                </div>
 
            </div>
            <br></br>

            <div className="form-row" >
                <div className="form-group col-md-10">
                    <Button 
                    className="btn btn-primary" 
                    type="submit" 
                    >
                        Gravar</Button>
                {/* </div>
                <div className="form-group col-md-2"> */}
                   
                </div>    
            </div> 
        </div>  
        </Form> 
           </div> 
            </div> 
        <Modal show={exibirModal} onHide={handleFecharModal} backdrop="static">
                <Modal.Header>
                        <h2> Cadastro Clínica </h2>
                </Modal.Header>
                <Modal.Body>
                        Gravado!
                </Modal.Body>
                <Modal.Footer>
                        <Button variante="success" onClick={() => handleFecharModal()}> Ok </Button>
                </Modal.Footer>
                
        </Modal>
        <Modal show={modalMSG}>
                <Modal.Header> 
                    <h3>{MSG}</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="info" onClick={() => setModalMSG(false)}>Ok</Button>
                </Modal.Footer>
            </Modal> 

        </div>
        )
}
export default Clinica;