import React, {useState, useEffect} from 'react';
// import { Jumbotron } from 'react-bootstrap';
import logo from '../img/medsolutions.jpg';
import { Card, Table } from 'react-bootstrap';
import consultasHoje from './consultasHoje';
import DashAvisos from './dashboads/dashAvisos';
import DashConsultas from './dashboads/dashConsultas';
import DashCPacientes from './dashboads/dashPacientes';
import './dashboard.css'


function DashBoard() {

    return ( 
        <div>
              <center>
                <br />
                <br />
                    <img src={logo} width="380" height="100" alt=""/>
                    <br />
                <br />
            </center> 
                {/*    <div>
                        <div style={{display: "inline-block", padding: "10px", width:"400px",background: "#f8f9fa"}}><DashAvisos /></div>
                    </div> */}
<br />
<div className="dashboard"> 
    <div className="grid-container"> 
        <div className="grid-item"><DashConsultas /></div> 
        <div className="grid-item"><DashAvisos /></div> 
        <div className="grid-item"><DashCPacientes /></div> 
    </div> 
</div>


        </div>

    )
}

export default DashBoard;