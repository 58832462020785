import React, { useState, useEffect} from 'react';
import api from '../conexao.js';

import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState,  convertToRaw, ContentState } from 'draft-js';

import {Modal, Form, Button} from 'react-bootstrap';
import logOut from '../logOut';

export default function Consulta(props){

const [html, setHTML] = useState('');
const [contentBlock] = useState(htmlToDraft(html));
const [contentState] = useState(ContentState.createFromBlockArray(contentBlock.contentBlocks));
const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));   
const [editorStateAnt, setEditorStateAnt] = useState(EditorState.createWithContent(contentState));   

const [textoBanco, setTextoBanco] = useState();
const [data, setData] = useState(new Date())

const [consulta, setConsulta] = useState([]);
const [codConsulta, setCodConsulta] = useState();
const [disableData, setDisableData] = useState(false);


const [edicao, setEdicao] = useState(false);

const [auxStatus, setAuxStatus] = useState(false)
const [btnCopiar, setBtnCopiar] = useState (true)

const [modalMSG, setModalMSG] = useState(false);
const [MSG, setMSG] = useState();
const [modalAnamneseAnt, setModalAnamneseAnt] = useState(false);
const [loading, setLoading] = useState(true)
var entreLinhas;



function onEditorStateChange(editorState) {
    setEditorState(editorState)
    setTextoBanco(draftToHtml(convertToRaw(editorState.getCurrentContent())));

};

function onEditorStateChangeAnt(editorStateAnt) {
    setEditorStateAnt(editorStateAnt)
    
};

function transformaDataBR(d){
    var date = new Date(d)                      // converte a data ISO DateTimeStamp(como vem do banco) para DD/MM/YYYY
    date.setHours(0,0,0,0)                      // zera a hora 00:00:00
    return(date.getDate()+'/' + (date.getMonth()+1) + '/'+date.getFullYear())      
}



useEffect(() => {
    

    async function buscaConsulta() {
        await api.get('/consulta',{
        params: {           
            idPac: props.idPac
        }                
    }).then ( result => {
        
        result.data.sort((COD1, COD2) => {// ORDENA LISTA DO COMBO POR CODIGO.
            COD1 = COD1.CODIGO;
            COD2 = COD2.CODIGO;

            if (COD1 > COD2) {
                return -1;
            }
            if (COD1 < COD2) {
                return 1;
            } return 0;
        })         

            result.data.map( r => {
                // console.log(r.DATA)
                r.DATA = transformaDataBR(r.DATA);                   
            })
            setConsulta(result.data);
            if (result.data[0]) {
                setCodConsulta(result.data[0].CODIGO);   

                setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(result.data[0].TEXTOCONVERTIDO))));
            } else {
            }
            
    }
    ).catch( (error) => {
        setMSG(logOut(error))
        setModalMSG(true)
    })
}



    
    buscaConsulta();
    setEdicao(true);
    setLoading(false);
    if (props.usuarioZap === 'Dr. Pedro Mota') {entreLinhas = '100%'} else {entreLinhas = '75%'}

    
},[props.idPac]);

   

    


////////////////////////
async function handleNovaConsulta(){
           // aux para saber se é um novo receituario se TRUE é novo RECIETA
    data.setHours(0,0,0,0) 


    setEdicao(false) // destrava o botão GRAVAR

    setAuxStatus(true)
    setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(''))));
    
    setBtnCopiar(false);

    setDisableData(true) //desativa o campo de data para não poder trocar
}

function transformaData(d){                     // converte data = new Date() para DD/MM/YYYY
    var date = new Date(d)                      // converte a data ISO DateTimeStamp(como vem do banco) para DD/MM/YYYY
    date.setHours(0,0,0,0)                      // zera a hora 00:00:00
    return(date.getFullYear()+'/' + (date.getMonth()+1) + '/'+date.getDate())   
}




async function handleGravar(codRec){       
        await api.post('/consulta', {
            COD_PACIENTE: parseInt(props.idPac),
            DATA: transformaData(data),
            TEXTO: textoBanco,  
            TIPO_CONSULTA : 1
  }).then ( teste => {

    setMSG('Gravado')
    setModalMSG(true)
    
    async function buscaConsulta() {
        await api.get('/consulta',{
        params: {           
        idPac: props.idPac
        }                
        }).then (result => {
            
            result.data.sort((COD1, COD2) => {// ORDENA LISTA DO COMBO POR CODIGO.
                COD1 = COD1.CODIGO;
                COD2 = COD2.CODIGO;
    
                if (COD1 > COD2) {
                    return -1;
                }
                if (COD1 < COD2) {
                    return 1;
                } return 0;
            }) 
            
            
            result.data.map( r => {
                r.DATA = transformaDataBR(r.DATA)    
            })

            setConsulta(result.data);
            setCodConsulta(result.data[0].CODIGO);            
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(result.data[0].TEXTOCONVERTIDO))));
            
        }).catch((error) => {
            // localStorage.setItem('anamnese', textoBanco)
            // setMSG(logOut(error))
            // setModalMSG(true)
        })
    }
            buscaConsulta();
            setDisableData(false) //Ativa o campo data para alterar, depois de gravado
            setBtnCopiar(true)
            setEdicao(true)
            


    })
    .catch( (error) => {
            // setMSG(logOut(error))
            // setModalMSG(true)
        })
}

function handleCodConsulta(e) {
     setCodConsulta(e)
     setEdicao(true)
     consulta.map( consult => {
         if (parseInt(consult.CODIGO) === parseInt(e)) {
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(consult.TEXTOCONVERTIDO))));  
         } 
        })
}

function handleCodConsultaAnt(e) {
    consulta.map( consult => {
        if (parseInt(consult.CODIGO) === parseInt(e)) {
           setEditorStateAnt(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(consult.TEXTOCONVERTIDO))));  
        } 
       })
}


// copia a anamnese anterior
function anamneseAnterior(e){
    if (e)  
    {let cons
    cons = consulta;
    
    setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(
        '<p>----------</p><br />' +
        cons[0].TEXTOCONVERTIDO))));   
    }
}

function handleCancelar() {

    async function buscaConsulta() {
        await api.get('/consulta',{
        params: {           
        idPac: props.idPac
        }                
        }).then (result => {
            
            result.data.sort((COD1, COD2) => {// ORDENA LISTA DO COMBO POR CODIGO.
                COD1 = COD1.CODIGO;
                COD2 = COD2.CODIGO;
    
                if (COD1 > COD2) {
                    return -1;
                }
                if (COD1 < COD2) {
                    return 1;
                } return 0;
            }) 
            
            
            result.data.map( r => {
                r.DATA = transformaDataBR(r.DATA)    
            })

            setConsulta(result.data);
            setCodConsulta(result.data[0].CODIGO);            
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(result.data[0].TEXTOCONVERTIDO))));
            
        }).catch( (error) => {
            setMSG(logOut(error))
            setModalMSG(true)
        })
    }

    setMSG('Cancelado!')
    setModalMSG(true) 
    buscaConsulta();
    setDisableData(false) //Ativa o campo data para alterar, depois de gravado
    setBtnCopiar(true)
    setEdicao(true)
    
}
function showAnamneseAnt(){
    setEditorStateAnt(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(consulta[0].TEXTOCONVERTIDO)))); 
    setModalAnamneseAnt(true)
}

return(
    <div className="container-fluid" >  
        <br />
        <div className="  form-row"> 
            <div className='btn-group-horizontal ln-md-1'>
                <Button variant="primary" size="sm"  onClick={() => handleNovaConsulta()}>Novo</Button>
                <span> </span> 
                <Button variant="info" size="sm" disabled={btnCopiar} onClick={() => anamneseAnterior(codConsulta)}>Copiar Anamnese Anterior</Button> 
                <span> </span>
                <Button variant="info" size="sm" disabled={btnCopiar} onClick={() => showAnamneseAnt()}>Visualizar Anamneses Anteriores</Button> 
            </div>   
            <br />       
                <div class="row">
                    <div class="col-md-1">
                        Atendimentos: 
                    </div>
                    <div class="col-md-2">

                                   {/* CONDICIONAL PARA MOSTRAR DATA DO DIA QUANDO CLICAR EM NOVO */}
                {disableData && 
                    <Form.Control  size="sm" as="select" 
                        value={codConsulta} 
                        onChange={e => handleCodConsulta(e.target.value)} 
                        disabled={disableData}>           
                        
                        <option >{transformaDataBR(data)}</option> 
                    </Form.Control> }

                                                    {/* SE disableData = false */}

                {!disableData && 
                    <Form.Control  size="sm" as="select" 
                        value={codConsulta} 
                        onChange={e => handleCodConsulta(e.target.value)} 
                        disabled={disableData}>           
                        {  consulta.map( consult => (
                            <option value={consult.CODIGO}>{consult.DATA}</option> 
                            ))}
                    </Form.Control> }
                   
                </div>
           
            </div>
            
    
        </div>
        <div className=" btn-group-vertical col-md-12 " >
            <div className="overflow-scroll" style={{height:"500px",  backgroundColor:'white'}} >
            <Editor 
                    editorState={editorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={onEditorStateChange}
                    placeholder="Digite o Texto"
                    editorStyle={{lineHeight: {entreLinhas}}}
                    // stripPastedStyles={true}         //COLA EM FORMATAÇAO
                />
                </div>
        </div> 
        <br />
        <span> </span>
        <div className=" btn-group-vertical col-md-1">
            <Button variant="primary" disabled={edicao} onClick={() => handleGravar(codConsulta)}>Gravar</Button>
        </div>
        <div className=" btn-group-vertical col-md-1">
            <Button variant="danger" disabled={edicao} onClick={() => handleCancelar()}>Cancelar</Button>
        </div>
        
        
        


    <Modal show={modalMSG}>
        <Modal.Header> 
            <h3>{MSG}</h3>
        </Modal.Header> 
        <Modal.Footer>
            <Button variant="info" onClick={() => setModalMSG(false)}>Ok</Button>
        </Modal.Footer>
    </Modal> 
    <Modal show={modalAnamneseAnt} size="lg">
        <Modal.Header> 
            <h3>Atendimentos</h3>
            <br />

        </Modal.Header> 
        <Modal.Body>
            <Form.Control  size="sm" as="select"
            //  value={codConsulta} 
             onChange={e => handleCodConsultaAnt(e.target.value)} 
            >           
                {consulta.map( consultAnaAnt => (
                    <option value={consultAnaAnt.CODIGO}>{consultAnaAnt.DATA}</option> ))}
                <br />
            </Form.Control>
            <div className="overflow-scroll"  style={{height:"550px"}} >
                <Editor 
                    editorState={editorStateAnt}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={onEditorStateChangeAnt}
                    placeholder="Digite o Texto"
                    toolbarClassName="toolbar-class"
                    editorStyle={{lineHeight: {entreLinhas}}}
                    toolbar={{
                        options: ['inline', 'blockType']
                      }}
                />
            </div>

            
        </Modal.Body>
        <Modal.Footer>
            <Button variant="info" onClick={() => setModalAnamneseAnt(false)}>Fechar</Button>
        </Modal.Footer>
    </Modal> 
        <Modal show={loading}>
               <Modal.Body>
                Aguarde....
                </Modal.Body>
        </Modal>  
    </div>
)

}

