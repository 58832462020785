import React from 'react';
import { useState } from 'react';
import { Form, Button, Modal} from 'react-bootstrap';
import Axios from 'axios';
import App from './App';
import logo from './img/medsolutions.jpg'; //teste

export default function Login(){

    const [userName, setUserName] = useState();
    const [password, setPassword] = useState();
    const [mostraModalErro, setMostraModalErro] = useState(false);
    const [msgErro, setMsgErro] = useState();
    var tokenBack;
    var tokenBackRefresh;
    const [app, setApp] = useState(false);
    var tentativa;

    async function handleLogin(){

       const autenticaLogin = async () => { 
            
            if (!userName) {
                    setMsgErro('Preecha o Usuário!')
                    setMostraModalErro(true)

            }else {

             //    const result = await Axios.get('https://18.230.32.253:443/login',{
              // const result = await Axios.get('https://api.solutionsti.info:443/login',{
                        //  const result = await Axios.get('http://10.0.0.84:3030/login',{
                        // const result = await Axios.get('http://10.0.0.142:3030/login',{
                            const result = await Axios.get('https://apisti.ddns.net/login',{ //DigitalOcean
                // const result = await Axios.get('http://localhost:3030/login',{
                    auth: {
                        username : userName,
                        password : password
                    }}  
                ).then((result) => {
                tokenBack = result.data;
                tokenBack = JSON.stringify(tokenBack)
                tokenBack = JSON.parse(tokenBack)
                // tokenRefresh
                tokenBackRefresh = result.data;
                tokenBackRefresh = JSON.stringify(tokenBackRefresh)
                tokenBackRefresh = JSON.parse(tokenBackRefresh)
                
                // GRAVA NO LOCALSTORAGE O TOKEN PARA SER ACESSADO NO MENU.
                localStorage.setItem('token', tokenBack.token)
                localStorage.setItem('tokenRefresh', tokenBackRefresh.tokenRefresh)
                // localStorage.setItem('userName', userName)
                // VARIAVEL RENDERIZAÇÃO CONDICINAL, SE TRUE CHAMA <App />
                setApp(true)
                }).catch((error) => { 
                    console.log(error)
                    modalErro(error)  
                
                // catch((error) => {
                //     alert(`${error} - Usuário e Senha Incorretos!`)
                })
            }

        }

            autenticaLogin()  

    }

    function modalErro(error){
        logOut(error)
        setMostraModalErro(true);
        // tentativa = tentativa + 1;
    }   

    function logOut(error){

        if (error.response.data.includes('banco')) {
            // localStorage.removeItem('token')
            setMsgErro('Usuário ou Senha inválidos!')
            
        } else {
        if (error.message.includes('401')) {
            // localStorage.removeItem('token')
            setMsgErro('Sessão Expirada! Faça o Login Novamente.')
        }}

        if (error.message.includes('404')){
        
            setMsgErro('EndPoint não Existe!')
        }
        if (error.message.includes('Network')){
            setMsgErro('Servidor Fora do ar, Contate o Administrador.')
        }
        if (error.message.includes('banco')){
            setMsgErro('Usuário ou Senha Incorretos.')
        }
        // else {
        //     setMsgErro(`${error.message}, Contate o Administrador!`)
        //     }   
    }

    function reloadPage(){
        if (msgErro === 'Preecha o Usuário!') {
            setMostraModalErro(false)
        }else{
            setMostraModalErro(false)
            window.location = '/'
        }
        
        
    }


if (app) { return (

 
         
           
                
                    <App />
                
          
            



) }
else {
    return(
        <div>
            {/* <div className="container"> */}
                <div className="row justify-content-center align-items-center" >
                    <div className='fluid'>
                      
                      <br />
                        <div >
                            <div >
                            <br /> 
                            <center>
                                
                                <img src={logo} width="380" height="100" alt=""/>
                            
                              <br />          
                              <br /> 
                                             
                                <Form action="" autoComplete="off" >
                                    <div className="form-group col-sm-4">
                                        Usuário:
                                        <input type="text" style={{ fontSize: "20px"}}  onChange={(e) => setUserName(e.target.value.toUpperCase())} className="form-control" name="username"  />
                                    </div>
                                    <br/>
                                    <div className="form-group col-sm-4">
                                        Senha:
                                        <input type="password" onChange={(e) => setPassword(e.target.value)} className="form-control" name="password" />
                                    </div>
                                    <br/>
                                    <div className="d-grid gap-2 col-sm-4">
                                        <button  type="button" className="btn btn-primary btn-lg btn-block" onClick={() => {handleLogin()}} >Entrar</button>
                                    </div>
                                </Form>
                                </center> 
                            </div>
                        </div>
                    </div>
            <Modal show={mostraModalErro}>
                <Modal.Header> 
                    <h4>{msgErro}</h4>
                </Modal.Header> 
                <Modal.Footer>
                    <Button type="button" onClick={() => reloadPage()}>Fechar</Button>
                </Modal.Footer>
            </Modal> 
            </div>
       
        </div>
    )
}

}