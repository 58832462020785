import React, { useState, useEffect} from 'react';
import api from '../conexao.js';
import {Tabs, Tab, Modal,Button} from 'react-bootstrap';
import { useParams } from "react-router-dom";

import Anamnese from './anamnese';
import Atestado from './atestado';
import Exame from './exame';
import Receituario from './receituario';
// import Recibos from './recibos';
import PedidosdeExame from './pedidosdeexame';
// import RiscoCirurgico from './riscocirurgico';
import logOut from '../logOut';
import Menu from '../menu';




export default function FichaPaciente() {

    
    const exameToAvaliacao = ["MARINAP","ADELLEC", "MARINAM","VANESSAM", "PRISCILLA"];
    const exameToDieta = ["Dra. Giovana Titoneli"];


    const [mostraAvaliacao, setMostraAvaliacao] = useState(false);
    const [mostraDieta, setMostraDieta] = useState(false);
    const [mostraExame, setMostraExame] = useState(false);


    const [paciente, setPaciente] = useState([]);
    const [medico, setMedico] = useState([]);
    const [key, setKey] = useState(1); // aqui marca a primeira tab a ser mostrada

    const {idMed} = useParams();
    const {idPac} = useParams();
    const [cab,setCab] = useState();
    const [rod,setRod] = useState();
    const [loading, setLoading] = useState(true);
    const [modalMSG, setModalMSG] = useState(false);
    const [MSG, setMSG] = useState();
    const [zap, setZap] = useState();
    var authKey =  "woSDS1DYcdrfdVFTwKCBk1bjPDxR4o6EdsLs6io7er6W5UAbHV";
    const [nomeUser, setNomeUser] = useState();
    // var userName
    
useEffect( () => {
    
 async   function busca() {
  await  api.get('/paciente/', {params:{
                                codigo: parseInt(idPac)
                                }} )
        .then( 
        result => {
            setPaciente(result.data) // joga a resposta em paciente
        }).catch( (error) => {
            setLoading(false)
            setMSG(logOut(error))
            setModalMSG(true)
        })

        .then(await api.get('/medico/', {params:{
                                            codigo: parseInt(idMed)
                                        }}).then (
            result => {
                setMedico(result.data) //joga a resposta em medico
                if (result.data.RODAPE == '' || result.data.RODAPE == null ) { setRod(0) } else { setRod(1) }
                if (result.data.CABECALHO == '' || result.data.CABECALHO == null ) { setCab(0) } else { setCab(1) }
                setLoading(false)
            }
            
            ).catch( (error) => {
                setLoading(false)
                setMSG(logOut(error))
                setModalMSG(true)
            })
            
            )

           
            .catch( (error) => {
                setLoading(false)
                setMSG(logOut(error))
                setModalMSG(true)
            })
    }

   async function verificaProfissional(){
        
        let tokin = localStorage.getItem('token')  //LE O TOKEN QUE FOI GRAVADO NO LOGIN

        var payload = tokin.split('.')[1];
        var decodedPayload = JSON.parse(window.atob(payload));

        var userName = decodedPayload.param2;
        setNomeUser(decodedPayload.param2);
        
        if (exameToAvaliacao.find((e) => e == nomeUser )) { setMostraAvaliacao(true)} else {
            if (exameToDieta.find((e) => e == nomeUser )) { setMostraDieta(true)} 
                else {setMostraExame(true)} }

    // WHATSAPP botao
    // console.log(userName + 'userName')
    await api.post('https://www.chatbot.menuia.com/api/developer', { 
        authkey: authKey,
        message: userName,
        checkDispositivo: 'true'
}).then((result) => {  if (result.data.message === 'Dispositivo Conectado') {
    setZap(false)

} else {setZap(true)}
                            
        }) // AO CONTRARIO PARA FUCNAO DISABLED DO BOTAO
        .catch(() => setZap(true)) 

    }

    busca();
    verificaProfissional();
},[])
      

function calcIdade(dateString) {
    var birthday = +new Date(dateString);
    return ~~((Date.now() - birthday) / (31557600000));
  }


    return(

        <div  className='fichaPaciente-formContainer'>
           <div >
                <div style={{display: "inline-block", padding: "10px"}}>
                    <div><strong>Dr.:</strong>  {medico.NOME}</div>
                    <div><strong>Paciente: <font size="5"> {paciente.NOME}</font></strong></div>
                </div>
                <div style={{display: "inline-block", padding: "10px"}}>
                    <div><strong>Idade: </strong>{calcIdade(paciente.DATA_NASC) }</div>
                    {paciente.SEXO == 'M' && <div><strong>Sexo: </strong>MASCULINO</div>}
                    {paciente.SEXO == 'F' && <div><strong>Sexo: </strong>FEMININO</div>}
                    {!paciente.SEXO && <div><strong>Sexo: </strong></div>}
                </div>
                <div style={{display: "inline-block", padding: "10px"}}>
                    <div><strong>Cidade: </strong>{paciente.CIDADE}</div>
                    <div><strong>Plano: </strong>{paciente.PLANO}</div>
                </div>
            <Tabs 
            id="controlled-tab-example" 
            activeKey={key}                 // variavel que guarda a TAB default     
            onSelect={(k) => setKey(k)}     // funcao para mudar a TAB
            unmountOnExit={true}            // if true desmonta a tab do DOM se nao estiver visivel
            >

                    <Tab eventKey={1} title="Anamnese/Evolução" 
                    label="Anamnese/Evolução" 
                    unmountOnExit={false} // if false ele nao desmonta a tab quando sai dela! ai qunando volta nela está como antes
                    tabClassName="custom-tab-button"
                    > 
                        <Anamnese idPac={idPac}
                        usuarioZap={nomeUser}
                         />
                    </Tab>
                    {!mostraAvaliacao &&
                    <Tab eventKey={2} title="Receituário" label="Receituário" tabClassName="custom-tab-button">
                        <Receituario idPac={idPac} 
                                    paciente={paciente.NOME} 
                                    idMed={idMed} 
                                    nomeMed={medico.NOME}
                                    crmMed={medico.CRM}
                                    cabecalho={cab}
                                    rodape={rod}
                                    titulo='Receituário'
                                    celular={paciente.CEL}  //CELULAR DO PACIENTE PARA ENVIAR WHATSAPP 
                                    whatsapp={zap}  //MENUIA CONECTADO OU NÃO
                                    usuarioZap={nomeUser}
                                    />        
                    </Tab>}
                    <Tab eventKey={3} title='Pedidos de Exame' label="Pedidos de Exame" tabClassName="custom-tab-button">
                        <PedidosdeExame idPac={idPac} paciente={paciente.NOME}
                                                            idMed={idMed} 
                                                            nomeMed={medico.NOME}
                                                            crmMed={medico.CRM}
                                                            cabecalho={cab}
                                                            rodape={rod}
                                                            titulo='Pedido de Exames'
                                                            celular={paciente.CEL}
                                                            whatsapp={zap}
                                                            usuarioZap={nomeUser}/>
                    </Tab>
                    <Tab eventKey={4} title="Atestado" label="Atestado" tabClassName="custom-tab-button">
                        <Atestado idPac={idPac} paciente={paciente.NOME} 
                                                            idMed={idMed} 
                                                            nomeMed={medico.NOME}
                                                            crmMed={medico.CRM}
                                                            cabecalho={cab}
                                                            rodape={rod}
                                                            titulo='Atestado'
                                                            celular={paciente.CEL}
                                                            whatsapp={zap}
                                                            usuarioZap={nomeUser}/>
                    </Tab>
                    {mostraAvaliacao && // se avaliação false mostra  Exame normal. senão mostra avaliação
                    <Tab eventKey={5} title="Avaliação" label="Exame" tabClassName="custom-tab-button" >
                        <Exame idPac={idPac} paciente={paciente.NOME} 
                                                            idMed={idMed} 
                                                            nomeMed={medico.NOME}
                                                            crmMed={medico.CRM}
                                                            cabecalho={cab}
                                                            rodape={rod}
                                                            titulo='Avaliação'
                                                            celular={paciente.CEL}
                                                            whatsapp={zap}
                                                            usuarioZap={nomeUser}/>
                    </Tab>
                    }
                    {mostraDieta &&
                    <Tab eventKey={5} title={"Dieta"} label="Exame" tabClassName="custom-tab-button">
                        <Exame idPac={idPac} paciente={paciente.NOME} 
                                                            idMed={idMed} 
                                                            nomeMed={medico.NOME}
                                                            crmMed={medico.CRM}
                                                            cabecalho={cab}
                                                            rodape={rod}
                                                            titulo='Orientação Nutricional'
                                                            celular={paciente.CEL}
                                                            whatsapp={zap}
                                                            usuarioZap={nomeUser}/>
                    </Tab>
                    } 
                    {mostraExame &&
                    <Tab eventKey={5} title={"Exame"} label="Exame" tabClassName="custom-tab-button">
                        <Exame idPac={idPac} paciente={paciente.NOME} 
                                                            idMed={idMed} 
                                                            nomeMed={medico.NOME}
                                                            crmMed={medico.CRM}
                                                            cabecalho={cab}
                                                            rodape={rod}
                                                            titulo='Exame'
                                                            celular={paciente.CEL}
                                                            whatsapp={zap}
                                                            usuarioZap={nomeUser}/>
                    </Tab>
                    }
                    
                    {/* <Tab eventKey={6}title="Risco Cirúrgico" label="Risco Cirúrgico">
                        <RiscoCirurgico idPac={idPac} paciente={paciente.NOME}
                                                            idMed={idMed} 
                                                            nomeMed={medico.NOME}
                                                            crmMed={medico.CRM}/>
                    </Tab> */}
                    {/* <Tab eventKey={7} title="Recibos"  label="Recibos">
                        <Recibos idPac={idPac} paciente={paciente.NOME}  
                                                            idMed={idMed} 
                                                            nomeMed={medico.NOME}
                                                            crmMed={medico.CRM}/>
                    </Tab> */}
            </Tabs>
        </div>
    <Modal show={loading}>
        <Modal.Body>
        Aguarde....
        </Modal.Body>
    </Modal>  

    <Modal show={modalMSG}>
        <Modal.Header> 
            <h3>{MSG}</h3>
        </Modal.Header> 
        <Modal.Footer>
            <Button variant="info" onClick={() => setModalMSG(false)}>Ok</Button>
        </Modal.Footer>
    </Modal> 
    </div>

    )
}