import React, { useState, useEffect} from 'react';
import api from '../conexao.js';

import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import htmlToPdfMake from 'html-to-pdfmake';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState,  convertToRaw, ContentState } from 'draft-js';

import {Modal, Form, Button, Spinner} from 'react-bootstrap';
import logOut from '../logOut';
import PrintReports from './print.js';
import ReceituarioControlado from './controlado.js';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons"
import { sendFile } from '../whatsApp/sendZap.js';
import './editorTexto.css';
import {removeBlankLines,removeFontAttributes}  from './draftToPdfMake';
// import { urlPreSignn } from './urlpresign.js';
// import { constrainedMemory } from 'process';

export default function Receituario(props){

const [html, setHTML] = useState('');
const [contentBlock] = useState(htmlToDraft(html));
const [contentState] = useState(ContentState.createFromBlockArray(contentBlock.contentBlocks));
const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));   

const [textoBanco, setTextoBanco] = useState();
const [data, setData] = useState(new Date())

const [receituario, setReceituario] = useState([]);
const [codReceituario, setCodReceituario] = useState();
const [medicamento, setMedicamento] = useState([]);
const [codMedicamento, setCodMedicamento] = useState();
const [disableData, setDisableData] = useState(false);
const [mostraModal, setMostraModal] = useState(false);
const [mostraModalRel, setMostraModalRel] = useState(false);
const [modalGravado, setModalGravado] = useState(false);
const [modalMSG, setModalMSG] = useState(false);
const [modalImprime, setModalImprime] = useState(false);
const [modalEnviar, setModalEnvia] = useState(false);
const [botaoEnv, setBotaoEnv] = useState(false)


const [auxStatus, setAuxStatus] = useState(false)
const [btnExcluir, setBtnExcluir] = useState(false);
// const [txt, setTxt] = useState();
const [MSG, setMSG] = useState();
const [medico, setMedico] = useState();
const [dataBR, setDataBR] = useState();
const [loading, setLoading] = useState(true)
const [send, setSend] = useState(false)
// var urlPreSign;
const pdfMake = require('pdfmake/build/pdfmake');
const pdfFonts = require('pdfmake/build/vfs_fonts');

const toolBarValues = {
    options: ["inline", "fontSize", "colorPicker", "textAlign"],
    inline: {
      inDropdown: false,
      options: ["bold", "italic", "underline", "strikethrough"]
    }
};


function onEditorStateChange(editorState) {
    setEditorState(editorState)
    setTextoBanco(draftToHtml(convertToRaw(editorState.getCurrentContent())));
};

function transformaData(d){                     // converte data = new Date() para DD/MM/YYYY
    var date = new Date(d)                      // converte a data ISO DateTimeStamp(como vem do banco) para DD/MM/YYYY
    date.setHours(0,0,0,0)                      // zera a hora 00:00:00
    return(date.getDate()+'/' + (date.getMonth()+1) + '/'+date.getFullYear())      
}

function transformaDataBanco(d){                     // converte data = new Date() para DD/MM/YYYY
    var date = new Date(d)                      // converte a data ISO DateTimeStamp(como vem do banco) para DD/MM/YYYY
    date.setHours(0,0,0,0)                      // zera a hora 00:00:00
    return(date.getFullYear()+'/' + (date.getMonth()+1) + '/'+date.getDate())   
}

useEffect(() => {
        
    async function buscaReceituario() {
        await api.get('/receituario',{
        params: {           
            idPac: props.idPac
        }                
    }).then ( result => {
        if (result.data[0]) {
        result.data.sort((COD1, COD2) => { // ORDENA LISTA DO COMBO POR CODIGO | ORDEM DECRESCENTE
            COD1 = COD1.CODIGO;
            COD2 = COD2.CODIGO;

            if (COD1 > COD2) {
                return -1;
            }
            if (COD1 < COD2) {
                return 1;
            } return 0;
        })

            result.data.map( r => {
                r.DATA = transformaData(r.DATA) 

            })
            
            setReceituario(result.data);
            if (result.data[0]) {
                setCodReceituario(result.data[0].CODIGO);   
                 setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft( result.data[0].TEXTOCONVERTIDO))));
                setBtnExcluir(false)
                setDataBR(result.data[0].DATA)
            } else {
                setBtnExcluir(true) 
            }   
            }else { 
                setBtnExcluir(true)
             }
    }).catch(
            (error) => {
            setMSG(logOut(error))
            setModalMSG(true)
            }
         )
    
    // .catch((error) => logOut(error))
}

    async function buscaMedicamento(){
        await api.get('/medicamento',{params:{
            nome: '%'
        }}).then (
            result => {
                setMedicamento(result.data)
                if (result.data[0]) {
                    setCodMedicamento(result.data[0].CODIGO) 
                    } 
            }, setLoading(false)
    ).catch(
        (error) => {
            setMSG(logOut(error))
            setModalMSG(true)
            }
        )
}
    // console.log(props.usuarioZap)
    buscaReceituario();
    buscaMedicamento();

},[props.idPac]);

 async function handleNovaReceita(){
           // aux para saber se é um novo receituario se TRUE é novo RECIETA
    data.setHours(0,0,0,0) 
    

    
    setDisableData(true) //desativa o campo de data para não poder trocar

    await api.post('/receituario', {
            COD_PACIENTE: parseInt(props.idPac),
            DATA: transformaDataBanco(data),
            TEXTO: '<p></p>'
 }).catch(
                
    (error) => {
        setMSG(logOut(error))
        setModalMSG(true)
    }
    
    )
    await api.get('/receituario',{
            params: {           
                idPac: props.idPac
            }                
    }).then ( result => {

                    result.data.sort((COD1, COD2) => {// ORDENA LISTA DO COMBO POR CODIGO.
                        COD1 = COD1.CODIGO;
                        COD2 = COD2.CODIGO;

                        if (COD1 > COD2) {
                            return -1;
                        }
                        if (COD1 < COD2) {
                            return 1;
                        } return 0;
                    })

                    result.data.map( r => {
                    r.DATA = transformaData(r.DATA)  

            }
    )

    setReceituario(result.data);
    setCodReceituario(result.data[0].CODIGO);
    setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(''))));
    setBtnExcluir(false)
}).catch(           
    (error) => {
        setMSG(logOut(error))
        setModalMSG(true)
    }
    )
setAuxStatus(true)
setBtnExcluir(false) // ATIVA O OBOTAO DE EXCLUIR
}

async function handleGravar(codRec){
        data.setHours(0,0,0,0) 
        setDataBR(transformaData(data))
        await api.put('/receituario/'+ codRec, {
            COD_PACIENTE: parseInt(props.idPac),
            DATA: transformaDataBanco(data),
            TEXTO: textoBanco
        }).then(  () =>  {setModalGravado(true)})
        .catch(
            (error) => {
                setMSG(logOut(error))
                setModalMSG(true)
            }
            )


    async function buscaReceituario() {
        await api.get('/receituario',{
        params: {           
            idPac: props.idPac
        }                
    }).then ( result => {

        result.data.sort((COD1, COD2) => {// ORDENA LISTA DO COMBO POR CODIGO.
            COD1 = COD1.CODIGO;
            COD2 = COD2.CODIGO;

            if (COD1 > COD2) {
                return -1;
            }
            if (COD1 < COD2) {
                return 1;
            } return 0;
        })           

            result.data.map( r => {
                r.DATA = transformaData(r.DATA)    
            })
          
            setReceituario(result.data);
            setCodReceituario(result.data[0].CODIGO);     
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(result.data[0].TEXTOCONVERTIDO))));

    })
    .catch( 
        (error) => {
            setMSG(logOut(error))
            setModalMSG(true)
        }
        )}
    buscaReceituario();
    setDisableData(false) //Ativa o campo data para alterar, depois de gravado
}

function handleCodReceituario(e) {
     setCodReceituario(e)
      
        
     receituario.map( receit => {
         if (parseInt(receit.CODIGO) === parseInt(e)) {
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(receit.TEXTOCONVERTIDO))));   
        
             setDataBR(receit.DATA)
        
            
        }
         
        })
        
}

function handleAdicionaMedicamento(){
    if ( typeof codMedicamento === 'undefined') {
        // alert('Selecione o Medicamento')
        setMSG('Selecione o Medicamento');
        setModalMSG(true);
    } else {

    medicamento.map( medic => {
        if (parseInt(medic.CODIGO) === parseInt(codMedicamento)) {
            onEditorStateChange(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(
                draftToHtml(convertToRaw(editorState.getCurrentContent())) + '<p>' + medic.NOME + '</p><p>' + medic.DOSE + '</p>'))));                         // VAI SER USADO PARA ADD TEXTO
        } 
    })
}

}

function handleMostraModal(m){
    setMostraModal(m)
}

async function handleExcluirReceita(cod){
    await api.delete('/receituario/'+ parseInt(cod))
    .then( () => {setMSG('Excluido')
            setModalMSG(true)}

    )
    .catch( 
            (error) => {
            setMSG(logOut(error))
            setModalMSG(true)
            }
        )
    
    setMostraModal(false);

     
    await api.get('/receituario',{
        params: {           
            idPac: props.idPac
        }                
        }).then (  
            result => {
            if (result.data[0]) {
                result.data.sort((COD1, COD2) => {// ORDENA LISTA DO COMBO POR CODIGO.
                    COD1 = COD1.CODIGO;
                    COD2 = COD2.CODIGO;
        
                    if (COD1 > COD2) {
                        return -1;
                    }
                    if (COD1 < COD2) {
                        return 1;
                    } return 0;
                })  
                
                result.data.map( r => {
                    r.DATA = transformaData(r.DATA)    
                })
                setDataBR(result.data[0].DATA)
                setReceituario(result.data);
                setCodReceituario(result.data[0].CODIGO);
                setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(result.data[0].TEXTOCONVERTIDO))));

            } else { //SE NAO TIVER RECEITUARIO APÓS APAGAR
            setReceituario(result.data)
            setBtnExcluir(true)
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(''))));
        }
    })    .catch( 
        (error) => {
            setMSG(logOut(error))
            setModalMSG(true)
        }
        )
}

function handleMostrarModalRel(m){
    setMostraModalRel(m);
}

// function setUrlPreSign(url){
//     urlPreSign = url;
// }



async function chamaPDF(send){
    
    // let nomeLinkR = 'https://imgmedsolutions.s3.sa-east-1.amazonaws.com/'+ 'R' + props.paciente.replaceAll(' ','+').toLowerCase() + '.pdf';
    var namePDF = 'R'+props.paciente.toLowerCase();
    // let auxTexto = convertToRaw(editorState.getCurrentContent()).blocks
    // let texto =  auxTexto.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
    var docDefinitions; //ARQUIVO COM AS DEFINICOES DO ARQUIVO PDF
    var bufferPDF;      // ARQUIVO PDF GERADL PELO MAKE AQUI NO RECEITUARIO
    var assinatura;

    let rawContentState = convertToRaw(editorState.getCurrentContent()); 

    let html = draftToHtml(rawContentState); 
    var texto = htmlToPdfMake(html);

    console.log(html)
    console.log(texto)

    // joga o lineHeight dentro do PDF em cada paragrafo.
    texto.forEach(element => { if (element.nodeName === 'P') { element.style = element.style || []; element.style.push({ lineHeight: 0.75 }); } });
    //remove a fonte
   
    
    // remove as linhas em branco abaixo
    texto = await removeBlankLines(texto);
    //remove font e fontSize
    texto = removeFontAttributes(texto)

    console.log(texto)

    await api.get('/medico/', {params: {
        codigo:parseInt(props.idMed)
    }} ).then ( (result) => { 
        if (result.data.ASSINATURA) {
            assinatura = Buffer.from(result.data.ASSINATURA).toString() // PEGA A BASE64 NO BANCO E TRANSFORMA EM ARQUIVO
        } 
    }  ).catch((error) => {setMSG(logOut(error))
                          setModalMSG(true)})
    

   function pdf(){                     // CHAMA O ARQUIVO QUE CRIA AS DEFINICOES DO PDF E DEPOIS CRIA ELE AQUI
        return new Promise((res,rej)=>{
            docDefinitions = PrintReports(texto, props.nomeMed, props.paciente, props.titulo, dataBR,props.crmMed,props.cabecalho,props.rodape,assinatura)  
            res()
        })
    }

    function geraBase64(){
        return new Promise((res,rej)=>{
            if (send) { pdfMake.createPdf(docDefinitions).getBuffer((buffer) => { // CRIA UM BUFFER E DEPOIS TRANSFORMA EM BASE64
                bufferPDF = buffer.toString('base64')                           // QUE PODE SER ENVIADO PELA API MENUIA WHATSAPP
                    })} else { pdfMake.createPdf(docDefinitions).open();}
            res()
        })
    }

    function enviaZap(){
        return new Promise((res,rej)=>{
            setTimeout(()=>                 // SETTIMEOUT PARA ESPERAR A FUNCAO DE CIMA TRABALHAR
            {
                if (send) { sendFile(props.celular,'Receituário',props.usuarioZap,namePDF,bufferPDF)} // FUNCAO QUE ENVIAR O BASE64 PRO ZAP
                setBotaoEnv(false)
                res()
            },5000)
        })
    }
    // CHAMA AS PROMISES, SÓ CONSEGUI ORGANIZAR QUEM EXECUTA PRIMEIRO COM PROMISES E TIMEOUT
    pdf().then(geraBase64().then(enviaZap()))
    setModalImprime(false);
    
 
} 

async function chamaPDFContolado(send){
 //   let nomeLinkRC = 'https://imgmedsolutions.s3.sa-east-1.amazonaws.com/'+ 'RC' + props.paciente.replaceAll(' ','+').toLowerCase() + '.pdf';
    var namePDF = 'RC'+props.paciente.toLowerCase();
    let auxTexto    = convertToRaw(editorState.getCurrentContent()).blocks
    let texto       = auxTexto.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
    var docDefControlado; //ARQUIVO COM AS DEFINICOES DO ARQUIVO PDF
    var bufferPDFControlado;      // ARQUIVO PDF GERADL PELO MAKE AQUI NO RECEITUARIO
    var ende;
    var assinatura;


    await api.get('/medico/', {params: {
                    codigo:parseInt(props.idMed)
                }} ).then ( (result) => {
                    ende = result.data.ENDERECO + ',' + result.data.BAIRRO + ' - ' + result.data.CIDADE +' - '+ result.data.TEL
                    assinatura = Buffer.from(result.data.ASSINATURA).toString('base64')
                },
                    // console.log(ende)
                    
                ).catch(
                    (error) => {
                        setMSG(logOut(error))
                        setModalMSG(true)})
    
    function pdf(){
        return new Promise((res,rej)=>{
            docDefControlado = ReceituarioControlado(ende,texto, props.nomeMed, props.paciente, props.titulo,dataBR,props.crmMed,props.cabecalho,props.rodape,assinatura)
            // console.log(docDefControlado)
            if (send) { pdfMake.createPdf(docDefControlado).getBuffer((buffer) => {
                bufferPDFControlado = buffer.toString('base64')
            })} else {pdfMake.createPdf(docDefControlado).download('ReceituarioControlado ' + props.paciente + '.pdf')}
            res()
        });
    }

    function enviaZap(){
        return new Promise((res,rej)=>{
            setTimeout(()=>                 // SETTIMEOUT PARA ESPERAR A FUNCAO DE CIMA TRABALHAR
            {
                if (send) { sendFile(props.celular,'Receituário Controlado',props.usuarioZap,namePDF,bufferPDFControlado)} // FUNCAO QUE ENVIAR O BASE64 PRO ZAP
                setBotaoEnv(false)
                res()
            },5000)
        })
    }
    // CHAMA AS PROMISES, SÓ CONSEGUI ORGANIZAR QUEM EXECUTA PRIMEIRO COM PROMISES E TIMEOUT
    pdf().then(enviaZap)
    // setModalEnvia(false)
    setModalImprime(false);
}


return(
    <div className="container-fluid" >  
        <br />
        <div className=" form-row">
            <div >
                <Button variant="primary" size="sm"  onClick={() => handleNovaReceita()}>Novo</Button>    
                <span> </span>               
                <Button variant="danger" size="sm" disabled={btnExcluir} onClick={() => handleMostraModal(true)}>Excluir</Button>            
                <span> </span> 
                <Button variant="info" size="sm" onClick={() => setModalImprime(true)}>Imprimir</Button>
                <span> </span>
                <Button disabled={props.whatsapp} size="sm" variant="success" onClick={()=> {setModalEnvia(true)}} ><FontAwesomeIcon  icon={faWhatsapp} size='lg' /></Button>
                    
            </div>
        <div className='row'>
            <div className="col-md-2">
                Receituários Emitidos:
                <Form.Control  size="sm" as="select" 
                value={codReceituario} 
                onChange={e => handleCodReceituario(e.target.value)} 
                disabled={disableData} >                               
                                    {receituario.map( receit => (
                                        <option value={receit.CODIGO}>{receit.DATA}</option>
                                        ))}
                </Form.Control> 
            </div>

            <div className="col-md-2">
                Medicamento:
                <Form.Control  size="sm" as="select" 
                value={codMedicamento} 
                onChange={e => setCodMedicamento(e.target.value)} >                               
                                    {medicamento.map( medic => (
                                        <option value={medic.CODIGO}>{medic.NOME}</option>
                                        ))}
                </Form.Control> 
                
            </div>
            <div className="col-md-2">
                <br />
                 <Button variant="success" size="sm" onClick={m => handleAdicionaMedicamento()} >Adicionar a Receita</Button>
            </div>
        </div>
        </div>
        <div className=" btn-group-vertical col-md-12 ">
            <div className="overflow-scroll" style={{height:"500px",backgroundColor:'white',width:'100%'}}>
                <div className="editor-container">
                    <Editor 
                            editorState={editorState}
                            wrapperClassName="demo-wrapper"
                            // editorClassName="demo-editor"
                            onEditorStateChange={onEditorStateChange}
                            placeholder="Digite o Texto"
                            toolbar={toolBarValues}
                            
                            //  editorStyle={{lineHeight: '75%'}} // esse funciona somente se nao alterar a formatação
                    />
                </div>
            </div>
        </div>
        <br />
        <div className=" btn-group-vertical col-md-1">
            <Button variant="primary" onClick={() => handleGravar(codReceituario)}>Gravar</Button>
        </div>
        <Modal show={mostraModal}>
            <Modal.Header> 
                <h3>Excluir Receita?</h3>
            </Modal.Header> 
            <Modal.Footer>
                <Button variant="danger" onClick={() => handleExcluirReceita(codReceituario)}>Excluir</Button>
                <Button variant="info" onClick={() => handleMostraModal(false)}>Cancelar</Button>
         </Modal.Footer>
        </Modal>

        <Modal show={modalGravado}>
                <Modal.Header> 
                    <h3>Gravado!</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="info" onClick={() => setModalGravado(false)}>Ok</Button>
                </Modal.Footer>
            </Modal> 

        <Modal show={mostraModalRel}>
            <Modal.Header> 
                <h3>Que tipo de Receita?</h3>
            </Modal.Header> 

        </Modal>
        <Modal show={modalMSG}>
                <Modal.Header> 
                    <h3>{MSG}</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="info" onClick={() => setModalMSG(false)}>Ok</Button>
                </Modal.Footer>
            </Modal> 
            <Modal show={modalImprime}>
                <Modal.Header> 
                    <h3>Qual tipo de Receituário?</h3>
                </Modal.Header> 
                <Modal.Footer> 
                    <Button variant="info" onClick={() => chamaPDF(false)}>Comum</Button>
                    <Button variant="info" onClick={() => chamaPDFContolado(false)}>Controlado</Button>
                    <Button variant="danger" onClick={() => setModalImprime(false)}>Fechar</Button>
                </Modal.Footer>
            </Modal> 
            <Modal show={modalEnviar}>
                <Modal.Header> 
                    <h3>Qual tipo de Receituário?</h3>
                </Modal.Header> 
                <Modal.Footer> 
                   {botaoEnv ? <>Enviando...<Spinner /></> : <><Button variant="info" onClick={() => {  chamaPDF(true)
                                                                                         setBotaoEnv(true)
                                                                                         }}>Comum</Button>
                    <Button variant="info" onClick={() => { chamaPDFContolado(true)
                                                             setBotaoEnv(true)}}>Controlado</Button>
                    <Button variant="danger" onClick={() => {  setModalEnvia(false)}}>Fechar</Button></>}
                </Modal.Footer>
            </Modal> 
            <Modal show={loading}>
                <Modal.Body>
                    Aguarde....
                </Modal.Body>
            </Modal>  
    </div>
)

}

