import React, { useState, useEffect} from 'react';
import api from '../conexao.js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState,  convertToRaw, ContentState } from 'draft-js';
import htmlToPdfMake from 'html-to-pdfmake';
import logOut from '../logOut';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons"
import {Modal, Form, Button, Spinner} from 'react-bootstrap';
import PrintReports from './print.js';
import { sendFile } from '../whatsApp/sendZap.js';
import {removeBlankLines,removeFontAttributes}  from './draftToPdfMake';

export default function PedidosdeExame(props){

    const [html, setHTML] = useState('');
    const [contentBlock] = useState(htmlToDraft(html));
    const [contentState] = useState(ContentState.createFromBlockArray(contentBlock.contentBlocks));
    const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));   
    
    const [textoBanco, setTextoBanco] = useState();
    const [data, setData] = useState(new Date())
    const [dataTexto, setDataTexto] = useState()
    
    const [pedidodeexame, setPedidodeExame] = useState([]);
    const [codPedido, setCodPedido] = useState();
    const [texto, setTexto] = useState([]);
    const [codTexto, setCodTexto] = useState();
    const [disableData, setDisableData] = useState(false);
    const [mostraModal, setMostraModal] = useState(false);
    const [modalGravado, setModalGravado] = useState(false);
    const [modalMSG, setModalMSG] = useState(false);

    
    const [auxStatus, setAuxStatus] = useState(false)
    const [btnExcluir, setBtnExcluir] = useState(false)
    const [MSG, setMSG] = useState();
    const [dataBR, setDataBR] = useState();
    const [loading, setLoading] = useState(true)
    // const [modalImprime, setModalImprime] = useState(false)
    const [modalEnviar, setModalEnvia] = useState(false)
    const [botaoEnv, setBotaoEnv] = useState(false)

    const pdfMake = require('pdfmake/build/pdfmake');
    // const pdfFonts = require('pdfmake/build/vfs_fonts');
    const toolBarValues = {
        options: ["inline", "fontSize", "colorPicker", "textAlign"],
        inline: {
          inDropdown: false,
          options: ["bold", "italic", "underline", "strikethrough"]
        }
    };

    function onEditorStateChange(editorState) {
        setEditorState(editorState)
        setTextoBanco(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };
    
    function transformaData(d){
        var date = new Date(d)                      // converte a data ISO DateTimeStamp(como vem do banco) para DD/MM/YYYY
        date.setHours(0,0,0,0)                      // zera a hora 00:00:00
        return(date.getDate()+'/' + (date.getMonth()+1) + '/'+date.getFullYear())      
    }

    function transformaDataBanco(d){                     // converte data = new Date() para DD/MM/YYYY
        var date = new Date(d)                      // converte a data ISO DateTimeStamp(como vem do banco) para DD/MM/YYYY
        date.setHours(0,0,0,0)                      // zera a hora 00:00:00
        return(date.getFullYear()+'/' + (date.getMonth()+1) + '/'+date.getDate())   
    }
    
    useEffect(() => {
            
        async function buscaPedidodeExame() {
            await api.get('/pedidodeexame',{
            params: {           
                idPac: props.idPac
            }                
        }).then ( result => {
            // console.log(result.data)
            result.data.sort((COD1, COD2) => {// ORDENA LISTA DO COMBO POR CODIGO.
                COD1 = COD1.CODIGO;
                COD2 = COD2.CODIGO;
    
                if (COD1 > COD2) {
                    return -1;
                }
                if (COD1 < COD2) {
                    return 1;
                } return 0;
            }) 

                result.data.map( (r) => {
                    r.DATA = transformaData(r.DATA)    
                })
                
                setPedidodeExame(result.data);
                
                if (result.data[0]) {                                        
                    setCodPedido(result.data[0].CODIGO); 
                    setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(result.data[0].TEXTOCONVERTIDO))));
                    setBtnExcluir(false)
                    setDataBR(result.data[0].DATA) 
                } else {
                    setBtnExcluir(true) 
                }
    
        }).catch(
            (error) => {
                setMSG(logOut(error))
                setModalMSG(true)
            })
    }
    
        async function buscaTexto(){
            await api.get('/textoportipo',{
                params: {           
                    tipoTexto: 10
                }                
            }).then ( result => {
                        
                        setTexto(result.data)
                        if (result.data[0]) {
                            setCodTexto(result.data[0].CODIGO) 
                            }  // para registrar o codTexto, pois select nao define codTexto 
                                                            //ao listar quando carrega tela.
            }).catch(
                (error) => {
                    setMSG(logOut(error))
                    setModalMSG(true)
                })
        }
        
        buscaPedidodeExame();
        buscaTexto();
        setLoading(false);
    
    },[props.idPac]);
    
     async function handleNovoPedido(){
               
        data.setHours(0,0,0,0) 
        
        setDisableData(true) //desativa o campo de data para não poder trocar
        
        await api.post('/pedidodeexame', {
                COD_PACIENTE: parseInt(props.idPac),
                DATA: transformaDataBanco(data),
                TEXTO: '<p></p>'
       }).catch(
        (error) => {
            setMSG(logOut(error))
            setModalMSG(true)
        })
    
        await api.get('/pedidodeexame',{
                params: {           
                    idPac: props.idPac
                }                
        }).then ( result => {

            result.data.sort((COD1, COD2) => {// ORDENA LISTA DO COMBO POR CODIGO.
                COD1 = COD1.CODIGO;
                COD2 = COD2.CODIGO;
    
                if (COD1 > COD2) {
                    return -1;
                }
                if (COD1 < COD2) {
                    return 1;
                } return 0;
            }) 

            result.data.map( r => {
            r.DATA = transformaData(r.DATA)    
        })
        setPedidodeExame(result.data);
        setCodPedido(result.data[0].CODIGO);
        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(''))));
        setDataTexto(transformaData(data))
        setBtnExcluir(false)
    }).catch(
        (error) => {
            setMSG(logOut(error))
            setModalMSG(true)
        })
    setAuxStatus(true)
    }
    
    async function handleGravar(codRec){
            
            data.setHours(0,0,0,0) 
            setDataBR(transformaData(data))
            console.log(codRec)
            await api.put('/pedidodeexame/'+ codRec, {
                COD_PACIENTE: parseInt(props.idPac),
                DATA: transformaDataBanco(data),
                TEXTO: textoBanco
            }).then(
                // setModalGravado(true),
                setMSG('Gravado!'),
                setModalMSG(true),
                setBtnExcluir(false)
            ).catch(
                (error) => {
                    setMSG(logOut(error))
                    setModalMSG(true)
                })
    
    
        async function buscaPedidodeExame() {
            await api.get('/pedidodeexame',{
                params: {           
                    idPac: props.idPac
                }                
        }).then ( result => {

            result.data.sort((COD1, COD2) => {// ORDENA LISTA DO COMBO POR CODIGO.
                COD1 = COD1.CODIGO;
                COD2 = COD2.CODIGO;
    
                if (COD1 > COD2) {
                    return -1;
                }
                if (COD1 < COD2) {
                    return 1;
                } return 0;
            }) 

                        result.data.map( r => {
                        r.DATA = transformaData(r.DATA)    
        })
        setPedidodeExame(result.data);
        setCodPedido(result.data[0].CODIGO);
        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(result.data[0].TEXTOCONVERTIDO))));
    
        }).catch(
            (error) => {
                setMSG(logOut(error))
                setModalMSG(true)
            })
    }
        buscaPedidodeExame();
        setDisableData(false) //Ativa o campo data para alterar, depois de gravado
    }
    
    function handleCodPedido(e) {
         setCodPedido(e)
         pedidodeexame.map( receit => {
             if (parseInt(receit.CODIGO) === parseInt(e)) {
                setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(receit.TEXTOCONVERTIDO))));  
                setDataTexto(receit.DATA)
                setDataBR(receit.DATA) 
             }
            })
    }
    
    function handleAdicionaTexto(){

        const substituirVariaveis = (texto, nome, data) => {
            // Substitui todas as ocorrências de %nome e %data pelo nome e data fornecidos
            let textoModificado = texto.replace(/%nome/g, nome);
            textoModificado = textoModificado.replace(/%data/g, data);
            return textoModificado;
        };

        var textoAlterado;
        if ( typeof codTexto === 'undefined' ) {
            // alert('Selecione o Texto')
            setMSG('Selecione o Texto');
            setModalMSG(true)
          } else {
        texto.map( text => {            
            if (parseInt(text.CODIGO) === parseInt(codTexto)) { // criar um .map para percorrer toda a string
                textoAlterado = text.TEXTOCONVERTIDO
                textoAlterado = substituirVariaveis(textoAlterado,props.paciente,dataTexto)
                // textoAlterado = textoAlterado.replace("%data", dataTexto)
                // textoAlterado = textoAlterado.replace("%nome", props.paciente)
                onEditorStateChange(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(
                    draftToHtml(convertToRaw(editorState.getCurrentContent())) + textoAlterado))));   // VAI SER USADO PARA ADD TEXTO
            } 
        })
    }
    
    }
     
    function handleMostraModal(m){
        setMostraModal(m)
    }
    
    async function handleExcluirPedido(cod){
        await api.delete('/pedidodeexame/'+ parseInt(cod))
        .then(
            setMSG('Apagado!'),
            setModalMSG(true)
        )
        .catch(
            (error) => {
                setMSG(logOut(error))
                setModalMSG(true)
            })
        setMostraModal(false);
    
         
        await api.get('/pedidodeexame',{
            params: {           
                idPac: props.idPac
            }                
            }).then ( result => {
                if (result.data[0]) {
                result.data.sort((COD1, COD2) => {// ORDENA LISTA DO COMBO POR CODIGO.
                    COD1 = COD1.CODIGO;
                    COD2 = COD2.CODIGO;
        
                    if (COD1 > COD2) {
                        return -1;
                    }
                    if (COD1 < COD2) {
                        return 1;
                    } return 0;
                }) 

                            result.data.map( r => {
                            r.DATA = transformaData(r.DATA)    
            })
            setPedidodeExame(result.data);
            setDataBR(result.data[0].DATA)
            setCodPedido(result.data[0].CODIGO);      
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(result.data[0].TEXTOCONVERTIDO))));            
          

        } else {
            setBtnExcluir(true)
            setPedidodeExame(result.data)
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(''))));
        }
            }).catch(
                (error) => {
                    setMSG(logOut(error))
                    setModalMSG(true)
                })             
    
    }

    async function chamaPDF(send){
        setBotaoEnv(true)
        // let nomeLinkR = 'https://imgmedsolutions.s3.sa-east-1.amazonaws.com/'+ 'R' + props.paciente.replaceAll(' ','+').toLowerCase() + '.pdf';
        var namePDF = 'PE'+props.paciente.toLowerCase();
        // let auxTexto = convertToRaw(editorState.getCurrentContent()).blocks
        // let texto =  auxTexto.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
        var docDefinitions; //ARQUIVO COM AS DEFINICOES DO ARQUIVO PDF
        var bufferPDF;      // ARQUIVO PDF GERADL PELO MAKE AQUI NO RECEITUARIO
        var assinatura;
        let rawContentState = convertToRaw(editorState.getCurrentContent()); 

        let html = draftToHtml(rawContentState); 
        var texto = htmlToPdfMake(html);

        console.log(html)
        console.log(texto)

        // joga o lineHeight dentro do PDF em cada paragrafo.
        texto.forEach(element => { if (element.nodeName === 'P') { element.style = element.style || []; element.style.push({ lineHeight: 0.75 }); } });
        //remove a fonte
        // texto.forEach(element => { if (element.text) { element.font = element.font = ''; element.fontSize = '' } });
        // remove as linhas em branco abaixo
        texto = await removeBlankLines(texto);
        // remove fonte
        texto = await removeFontAttributes(texto)
        
 
        console.log(texto)
    
        await api.get('/medico/', {params: {
            codigo:parseInt(props.idMed)
        }} ).then ( (result) => { assinatura = Buffer.from(result.data.ASSINATURA).toString() // PEGA A BASE64 NO BANCO E TRANSFORMA EM ARQUIVO
        }  ).catch((error) => {setMSG(logOut(error))
                              setModalMSG(true)})
    
       function pdf(){                     // CHAMA O ARQUIVO QUE CRIA AS DEFINICOES DO PDF E DEPOIS CRIA ELE AQUI
            return new Promise((res,rej)=>{
                docDefinitions = PrintReports(texto, props.nomeMed, props.paciente, props.titulo, dataBR,props.crmMed,props.cabecalho,props.rodape,assinatura)  
                res()
            })
        }
    
        function geraBase64(){
            return new Promise((res,rej)=>{
                if (send) { pdfMake.createPdf(docDefinitions).getBuffer((buffer) => { // CRIA UM BUFFER E DEPOIS TRANSFORMA EM BASE64
                    bufferPDF = buffer.toString('base64')                           // QUE PODE SER ENVIADO PELA API MENUIA WHATSAPP
                        })} else { pdfMake.createPdf(docDefinitions).open();}
                res()
            })
        }
    
        function enviaZap(){
            return new Promise((res,rej)=>{
                if (send) {
                setTimeout(()=>                 // SETTIMEOUT PARA ESPERAR A FUNCAO DE CIMA TRABALHAR
                {
                      sendFile(props.celular,'Pedido de Exame',props.usuarioZap,namePDF,bufferPDF) // FUNCAO QUE ENVIAR O BASE64 PRO ZAP
                     setBotaoEnv(false)
                    
                },5000)}
                res()
            })
        }
        // CHAMA AS PROMISES, SÓ CONSEGUI ORGANIZAR QUEM EXECUTA PRIMEIRO COM PROMISES E TIMEOUT
        pdf().then(geraBase64().then(enviaZap()))
        setBotaoEnv(false)
        
     
    }   
    
    return(
        <div className="container-fluid" >  
            <br />
            <div className=" form-row">
                <div >
                    <Button variant="primary" size="sm" onClick={() => handleNovoPedido()} >Novo</Button>   
                    <span> </span>                  
                    <Button variant="danger" size="sm" onClick={() => handleMostraModal(true)} disabled={btnExcluir} >Excluir</Button>            
                    <span> </span>   
                    <Button variant="info" size="sm" onClick={() => chamaPDF(false)}>Imprimir</Button>
                    <span> </span>
                    <Button disabled={props.whatsapp} size="sm" variant="success" onClick={()=>{setModalEnvia(true)}}><FontAwesomeIcon  icon={faWhatsapp} size='lg' /></Button>
                </div>
            <div className='row'>
                <div className="col-md-2">
                    Pedidos Emitidos:
                    <Form.Control  size="sm" as="select" 
                    value={codPedido} 
                    onChange={e => handleCodPedido(e.target.value)} 
                    disabled={disableData} >                               
                                        {pedidodeexame.map( pedid => (
                                            <option value={pedid.CODIGO}>{pedid.DATA}</option>
                                            ))}
                    </Form.Control> 
                </div>
                <div className="col-md-2">
                    Texto:
                    <Form.Control  size="sm" as="select" 
                    value={codTexto} 
                    onChange={e => setCodTexto(e.target.value)} >                               
                                        {
                                        texto.map( text => (
                                            <option value={text.CODIGO}>{text.NOME}</option>
                                            ))}
                    </Form.Control> 
                </div>
                
                <div className="col-md-2">
                        <br />
                        <Button variant="success" size="sm" onClick={m => handleAdicionaTexto()} >Adicionar Texto</Button>
                    </div>
            </div>
            </div>
            <div className=" btn-group-vertical col-md-12 ">
                <div className="overflow-scroll" style={{height:"500px" ,backgroundColor:'white',width:'100%'}}>
                    <Editor 
                            editorState={editorState}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            onEditorStateChange={onEditorStateChange}
                            placeholder="Digite o Texto"
                            toolbar={toolBarValues}
                            // defaultFontSize={16}
                            // editorStyle={{ fontFamily: 'Arial' }} // Define a fonte padrão via CSS
                        />
                </div>
            </div>
            <br />
            <div className=" btn-group-vertical col-md-1">
                <Button variant="primary" onClick={() => handleGravar(codPedido)}>Gravar</Button>
            </div>
            <Modal show={mostraModal}>
                <Modal.Header> 
                    <h3>Excluir Pedido?</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="danger" onClick={() => handleExcluirPedido(codPedido)}>Excluir</Button>
                    <Button variant="info" onClick={() => handleMostraModal(false)}>Cancelar</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={modalGravado}>
                <Modal.Header> 
                    <h3>Gravado!</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="info" onClick={() => setModalGravado(false)}>Ok</Button>
                </Modal.Footer>
            </Modal>      
            <Modal show={modalMSG}>
                <Modal.Header> 
                    <h3>{MSG}</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="info" onClick={() => setModalMSG(false)}>Ok</Button>
                </Modal.Footer>
            </Modal> 
            <Modal show={loading}>
               <Modal.Body>
                Aguarde....
                </Modal.Body>
        </Modal> 
        <Modal show={modalEnviar}>
                <Modal.Header> 
                    <h3>Enviar WhatsApp com Pedido de Exame</h3>
                </Modal.Header> 
                <Modal.Footer> 
                {botaoEnv ? <>Enviando...<Spinner /></> : 
                    <>
                    <Button variant="info" onClick={() => { chamaPDF(true)
                                                            setBotaoEnv(true)}}>Enviar</Button>
                    <Button variant="danger" onClick={() => {setModalEnvia(false)}}>Fechar</Button>
                    </>}
                </Modal.Footer>
            </Modal> 
      
        </div>
    )

}