import React, { useState, useEffect} from 'react';
import api from '../conexao.js';
import { format} from 'date-fns';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { faStethoscope } from "@fortawesome/free-solid-svg-icons"
import './dashCSS.css'
import { Modal, Button } from 'react-bootstrap';
import logOut from '../logOut.js';



export default function DashConsultas(){

    const [codMed, setCodMed] = useState();
    const [medicos, setMedicos] = useState();
    const [agenda, setAgenda] = useState();
    const [modalMSG, setModalMSG] = useState(false);
    const [MSG, setMSG] = useState();

    var data = new Date();
    var marcado     = 0;
    var confirmado  = 0;
    var atendido    = 0;
    var qtdAgendamentos = 0;
    var consulta    = 0;
    var priConsulta = 0;
    var revisao     = 0;
    var encaixe     = 0;
    var CodMed;


      useEffect( () => {


        function buscaMedico() { 
            return new Promise((res,rej) => 
                api.get('/medico',{
                    params: {
                        nome: '%'
                    }
                }).then( med => {
                    setMedicos(med.data)
                    CodMed = med.data[0].CODIGO
                    res()
                }).catch(
                    (error) => {
                            setMSG(logOut(error))
                            setModalMSG(true)  
                            rej()
                        }
                    )
            )
            
            
        }

        function buscaAgenda() {
                return new Promise((res,rej) => 
                    api.get('/imprimeagenda', { 
                        params: {           
                            med: CodMed,
                            data: format(data, 'yyyy-MM-dd') 
                        }
                        }).then(result => {
                            setAgenda(result.data)
                            res()
                        }).catch(
                            (error) => {
                                    setMSG(logOut(error))
                                    setModalMSG(true)
                                    rej()
                                }
                            )         
                    
                    )     
                    
        }

    buscaMedico().then(buscaAgenda());
    // buscaAgenda();



    },[]);

async function valores(){
   if (agenda) {
    await agenda.map(agendamento => {

        //marcado
        if (agendamento.COD_STATUS === 4) { marcado = marcado+ 1 } 
        //atendido
        if (agendamento.COD_STATUS === 2) { atendido = atendido+1 }
        //confirmado
        if (agendamento.COD_STATUS === 7) { confirmado = confirmado+1 }

        if (agendamento.COD_SITUACAO === 1) { consulta = consulta + 1 }
        if (agendamento.COD_SITUACAO === 2) { priConsulta = priConsulta + 1 }
        if (agendamento.COD_SITUACAO === 3) { revisao = revisao + 1 }
        if (agendamento.ENCAIXE === 3) { encaixe = encaixe + 1 }
        qtdAgendamentos = qtdAgendamentos+1;
    })
}
}
    
valores(); //chama para calcular 


    return (
        <>
<center>

            <div className='containerDash' > 
                <div style={{textAlign:"left"}}>
                    <h5>Consultas</h5>
                    <h6>{format(data, 'dd/MM/yyyy')}</h6>
                </div>
                <div ><FontAwesomeIcon icon={faStethoscope} size='xl' /></div>
           </div>
          
           <div className='containerDash'>
                <div  style={{textAlign:"left"}}>
                    
                    <h6>Total:</h6>
                    <h6 >Atendidos:</h6>
                    <h6 >Encaixes:</h6>
                </div>
                <div style={{textAlign:"right", paddingLeft:"10px"}}>
                    <h6>{qtdAgendamentos}</h6>
                    <h6 >{atendido}</h6>
                    <h6>{encaixe}</h6>
                </div>

                <div style={{width:"100px"}}></div>

                <div style={{textAlign:"left"}}>
                    <h6>Consulta:</h6>
                    <h6>Primeira Consulta:</h6>
                    <h6>Revisão:</h6>
                </div>
                {/* <div style={{width:"200px"}}></div> */}
   
                <div style={{textAlign:"right", paddingLeft:"10px"}}> 
                    <h6>{consulta}</h6>
                    <h6>{priConsulta}</h6>
                    <h6>{revisao}</h6>   
                </div>
                <br />
                <br />
                <br />
                <br />
        
                

          </div>

</center>
    <Modal show={modalMSG}>
        <Modal.Header> 
            <h3>{MSG}</h3>
        </Modal.Header> 
        <Modal.Footer>
            <Button variant="info" onClick={() => setModalMSG(false)}>Ok</Button>
        </Modal.Footer>
    </Modal>
        </>
    )
}