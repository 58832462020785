import React, { useState, useEffect, useRef }from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Button, Form } from 'react-bootstrap';
import api from '../conexao.js';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate, useParams } from 'react-router-dom';
import InputMask from 'react-input-mask';
import '../../App.css';
import logOut from '../logOut';
import AWS from 'aws-sdk';
import SignaturePad from 'react-signature-canvas';
import './CadastroMedico.css';



export default function EditarMedico(){

        const nave = useNavigate();
        const {id} = useParams();
// EditarMedico.propTypes = {
//     id: PropTypes.number.isRequired
// }

const {register, handleSubmit, setValue} = useForm( );  //REGISTRANDO AS FUNÇÕES DO USEFORM
const [exibirModal, setExibirModal] = useState(false); // const para definir se a modal aparece ou nao
const [medNome, setMedNome] = useState();
const [tel, setTEL] = useState();
const [cel, setCEL] = useState();
const [cpf, setCPF] = useState();
const [modalMSG, setModalMSG] = useState(false);
const [MSG, setMSG] = useState();
const [cabecalho, setCabecalho] = useState();
const [rodape, setRodape] = useState();
const assinatura = useRef({})
var sign;
var cabFile,rodFile;

var telnum = String;
var celnum = String;
var cpfnum = String;

useEffect( () => {
    api.get('/medico/',{  params: {           
        codigo: id,}}).then(result => {
           setValue("NOME", result.data.NOME);
           setValue("ENDERECO", result.data.ENDERECO);
           setValue("BAIRRO", result.data.BAIRRO);
           setValue("CIDADE", result.data.CIDADE);
           setValue("CRM", result.data.CRM);
           setCabecalho(result.data.CABECALHO);
           setRodape(result.data.RODAPE);
           if (result.data.ASSINATURA) {
                        sign = Buffer.from(result.data.ASSINATURA) ;
                        assinatura.current.fromDataURL(sign)// para dar load na assinatura.
                        // console.log(sign)
                }
           if (result.data.TEL) {  
             setTEL(result.data.TEL.toString());
             setValue("TEL", tel);
           }
           if (result.data.CEL) { 
             setCEL(result.data.CEL.toString());
             setValue("CEL", cel);
           }
           if (result.data.CPF) { 
             setCPF(result.data.CPF.toString());
             setValue("CPF", cpf);     
           }        

    }) .catch(        
                (error) => {
                setMSG(logOut(error))
                setModalMSG(true)
    })   
    
}, [id]);

function gravaAssinatura() {
        sign = assinatura.current.toDataURL() // GRAVAR A VARIAVEL SIGN NO BANCO E RESTAURAR PARA APARECER A ASSINATURA        
}

function handleFecharModal(){
        setExibirModal(false); // inicia faalse
        nave('/medico/medico'); // vai para pagina anterior
}
function handleVoltar(){
        nave('/medico/medico');
}

 const onSubmit = (data) => {

        if  (tel)  {
                telnum = tel;
                telnum = telnum.replace(/[^0-9]+/g, "");
        }
        if (cel) {
                celnum = cel;
                celnum = celnum.replace(/[^0-9]+/g, "");
        }
        if (cpf) {
                cpfnum = cpf;
                cpfnum = cpfnum.replace(/[^0-9]+/g, "");
        }
        // console.log(data.cabecalho)
        if (cabecalho) { data.CABECALHO = 'cab ' + data.NOME.substring(0,10).toLowerCase() + '.jpg'}
        if (rodape)    { data.RODAPE    = 'rod ' + data.NOME.substring(0,10).toLowerCase() + '.jpg'}

        //GRAVA ASSINATURA EM VAR sign
        gravaAssinatura()

async   function Grava() {
              await  api.put('/medico/'+ id, {
                        NOME:  data.NOME.toUpperCase(),
                        ENDERECO: data.ENDERECO.toUpperCase(),
                        BAIRRO: data.BAIRRO.toUpperCase(),
                        CIDADE: data.CIDADE.toUpperCase(),
                        CRM: data.CRM.toUpperCase(),
                        TEL: telnum,
                        CEL: celnum,
                        CPF: cpfnum,
                        CABECALHO: data.CABECALHO,
                        RODAPE: data.RODAPE,
                        ASSINATURA: sign
                }).then(
                        () => {
                        setMedNome(data.NOME);
                        setExibirModal(true);
                        }
                ).catch(        
                        (error) => {
                        setMSG(logOut(error))
                        setModalMSG(true)
                })   
        }    

 
        
        const uploadCabecalho = async () => {
                const S3_BUCKET = "imgmedsolutions";
                const REGION = "sa-east-1";
                AWS.config.update({
                  accessKeyId: "AKIAY3BB4HQM7BZWG7OA",
                  secretAccessKey: "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",
                });
                const s3 = new AWS.S3({
                  params: { Bucket: S3_BUCKET },
                  region: REGION,
                });
            
                const params = {
                  Bucket: S3_BUCKET,
                  Key: data.CABECALHO,
                  Body: cabFile,
                  Metadata: {
                        ContentType: 'image/jpeg',
                      }
                };
            
                var upload = s3
                  .putObject(params)
                  .on("httpUploadProgress", (evt) => {
                //     console.log(
                //       "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
                //     );
                  })
                  .promise();
            
                // await upload.then((err, data) => {
                //   console.log(err);
                //   alert("File uploaded successfully.");
                // });
                await upload.catch((err, data) => {
                        if (err) {console.log(err)}
                        alert("File uploaded Failed.");
                });
        };

        const uploadRodape = async () => {
                const S3_BUCKET = "imgmedsolutions";
                const REGION = "sa-east-1";
                AWS.config.update({
                        accessKeyId: "AKIAY3BB4HQM7BZWG7OA",
                        secretAccessKey: "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",
                });
                const s3 = new AWS.S3({
                        params: { Bucket: S3_BUCKET },
                        region: REGION,
                });

                const params = {
                        Bucket: S3_BUCKET,
                        // Key: rodape.name,
                        Key: data.RODAPE,
                        Body: rodFile,
                        Metadata: {
                          ContentType: 'image/jpeg',
                      }
                        
                };

                var upload = s3
                        .putObject(params)
                        .on("httpUploadProgress", (evt) => {
                        // console.log(
                        // "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
                        // );
                        })
                        .promise();

                // await upload.then((err, data) => {
                //         console.log(err);
                //         alert("File uploaded successfully.");
                // });
                await upload.catch((err, data) => {
                        if (err) {console.log(err)}
                        alert("File uploaded Failed.");
                });
        };

        console.log(cabFile)
        Grava();
        
       if (cabFile !== undefined) {uploadCabecalho()} 
        if (rodFile !== undefined) {uploadRodape()} 
      
    }


    function funcCabecalho(e) {
        const cabeca = e.target.files[0];
        // setCabecalho(cabeca);
        cabFile = cabeca;
    }

    function funcRodape(e) {
        const roda = e.target.files[0];
        // setRodape(roda);
        rodFile = roda;
    } 

        return (

                <div>
        

                <div className="cadastroMedico-formContainer">
                      <h2 className="cadastroMedico-title">Editar Médico</h2>
                      <form onSubmit={handleSubmit(onSubmit)} className='cadastroMedico-form '>
                        <div className="cadastroMedico-formGroup">
                          <label className="cadastroMedico-label">Nome:</label>
                          <input className="cadastroMedico-input" type="text" name="NOME" maxLength={45}
                          style={{textTransform:"uppercase"}} 
                          required
                          {...register('NOME',{minLength: 10})}  />
                        </div>
                        <div className="cadastroMedico-formGroup3">
                        <label className="cadastroMedico-label">CRM:</label>
                         <Form.Control style={{textTransform:"uppercase"}} 
                            type="text" 
                            size="sm" 
                            maxLength={15}
                            name="CRM" 
                            {...register('CRM')} 
                            required
                            />
                        </div>
                        <div className="cadastroMedico-formGroup">
                          <label className="cadastroMedico-label">Endereço:</label>
                          <input className="cadastroMedico-input" type="text" name="ENDERECO" maxLength={45}
                           {...register('ENDERECO')}
                           style={{textTransform:"uppercase"}}  />
                        </div>
                        <div className="cadastroMedico-formGroup">
                          <label className="cadastroMedico-label">Bairro:</label>
                          <input className="cadastroMedico-input" type="text" name="BAIRRO" maxLength={45}
                          style={{textTransform:"uppercase"}} 
                          {...register('BAIRRO')}/>
                        </div>
                        <div className="cadastroMedico-formGroup">
                          <label className="cadastroMedico-label">Cidade:</label>
                          <input className="cadastroMedico-input" type="text" name="CIDADE" maxLength={45}
                          style={{textTransform:"uppercase"}}
                          {...register('CIDADE')}/>
                        </div>
                        <div className="cadastroMedico-formGroup3">
                          <label className="cadastroMedico-label">Tel.:</label>
                          {/* <input className="cadastroMedico-input" type="text" name="telefone" {...register('TEL')} /> */}
                                 <InputMask
                                         mask="(99)9999-9999"
                                         size="24"
                                         name="TEL"
                                         {...register('TEL')}
                                         value={tel}
                                         onChange={(e) => setTEL(e.target.value)}
                                         alwaysShowMask={false}
                                 /> 
                        </div>
                        <div className="cadastroMedico-formGroup3">
                          <label className="cadastroMedico-label">Cel.:</label>
                          {/* <input className="cadastroMedico-input" type="text" name="celular" {...register('CEL')} /> */}
                          <InputMask
                                        mask="(99)99999-9999"
                                        size="24"
                                        name="CEL"
                                        {...register('CEL')}
                                        value={cel}
                                        onChange={(e) => setCEL(e.target.value)}
                                        alwaysShowMask={false}
                                />
                        </div>
                        <div className="cadastroMedico-formGroup3">
                          <label className="cadastroMedico-label">CPF:</label>
                          {/* <input className="cadastroMedico-input" type="text" name="cpf" {...register('CPF')}/> */}
                          <InputMask
                                        mask="999.999.999-99"
                                        size="24"
                                        name="CPF"
                                        {...register('CPF')}
                                        value={cpf}
                                        onChange={(e) => setCPF(e.target.value)}
                                        alwaysShowMask={false}
                                />
                        </div>
                        <div className="cadastroMedico-formGroup3">
                        <label className="cadastroMedico-label">Cabeçalho</label>
                                {cabecalho && <label style={{color: "red"}} > Já existe Cabeçalho associado!</label>}
                                
                                <Form.Control  type="file" name="cabecalho" accept="image/jpeg" style={{width:"143px"}} onChange={e => funcCabecalho(e)} />
                                
                                {cabecalho && <Button className="btn btn-danger" onClick={() => {setCabecalho()}}>Remover</Button>}
                        </div>

                        <div className="cadastroMedico-formGroup3">
                        <label className="cadastroMedico-label">Roda-pé</label>
                                {rodape && <label style={{color: "red"}} > Já existe Roda-pé associado!</label>}
                        
                                <Form.Control type="file" name="rodape" accept="image/jpeg" style={{width:"143px"}}  onChange={e => funcRodape(e)}/>
                                
                                {rodape && <Button className="btn btn-danger" onClick={() => {setRodape()}}>Remover</Button>}
                        </div>
                         
                        <div className="cadastroMedico-formGroup">
                           <label className="cadastroMedico-label">Assinatura</label>
                                <div>        
                                        <div style={{borderStyle: "solid", borderWidth: "1px", width:"320px"}}>
                                                <SignaturePad  ref={assinatura} canvasProps={{width: 320, height: 200}}/>    
                                        </div>
                                        <Button className="btn btn-warning"  onClick={()=> {assinatura.current.clear()}}>Limpar</Button>  
                                </div>
                        </div>
            

                        <div className="cadastroPaciente-formActions">
                          <Button className="btn-primary"  type="submit">Gravar</Button>
                          <Button className="btn-secondary" 
                          onClick={() => {handleVoltar()}}  type="submit">Cancelar</Button>
                        </div> 
                     </form>
                </div>
            


        <Modal show={exibirModal} onHide={handleFecharModal} backdrop="static">
                <Modal.Header>
                        <h2> Alteração feita com Sucesso! </h2>
                </Modal.Header>
                <Modal.Body>
                  Médico: <strong>{medNome}</strong>, Alterado!                  
                </Modal.Body>
                <Modal.Footer>
                        <Button variante="success" onClick={() => handleFecharModal()}> Ok </Button>
                </Modal.Footer>
                
        </Modal>
        <Modal show={modalMSG}>
                <Modal.Header> 
                    <h3>{MSG}</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="info" onClick={() => setModalMSG(false)}>Ok</Button>
                </Modal.Footer>
            </Modal> 
        </div>
 

                )

}



