import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Async from 'react-select/async';
import api from '../conexao.js';
import { Link, useParams, useNavigate } from 'react-router-dom';
import logOut from '../logOut';
import { IMaskInput } from "react-imask";




export default function Agendamento(){

const nave = useNavigate();
const {idMed, date,horaProps,encaixe} = useParams();


const {register, handleSubmit} = useForm( );  //REGISTRANDO AS FUNÇÕES DO USEFORM
const [codPaciente, setCodPaciente] = useState();
const [pacienteNome, setPacienteNome] = useState([]);
const [hora, setHora] = useState();
const [situacao, setSituacao] = useState([]);
const [status, setStatus] = useState([]);
const [mostrarModal, setMostrarModal] = useState(false);
const [mostrarModalCadPacNew, setMostrarModalCadPacNew] = useState(false);
const [CadPacNew, setCadPacNew] = useState(false);
const [nomeCadPacNew, setNomeCadPacNew] = useState();
const [celCadPacNew, setCelCadPacNew] = useState();

const [modalMSG, setModalMSG] = useState(false);
const [MSG, setMSG] = useState();
const [loading, setLoading] = useState(true);

function transformaDataBanco(d){                     // converte data = new Date() para DD/MM/YYYY
    var date = new Date(d)                      // converte a data ISO DateTimeStamp(como vem do banco) para DD/MM/YYYY
    date.setHours(0,0,0,0)                      // zera a hora 00:00:00
    return(date.getFullYear()+'/' + (date.getMonth()+1) + '/'+date.getDate())   
}

 
const loadOptions = (inputValue, callback) => {

 
        if (!inputValue || inputValue.length <= 4)  {
            callback([])
        } else {
        setTimeout(() => {
            api.get('/paciente', { 
                params: {           //BUSCA POR PARAMETRO ? NOME  
                nome: inputValue
                }}).then( result => {           // APARTIR DA 4 LETRA ELE COMEÇA A BUSCAR NO BANCO
                    const array = [];           // PENSAR EM UMA MANEIRA MELHOR PARA NAO FAZER VÁRIAS BUSCAS NA API
                    result.data.map(e  => (
                        array.push({label: `${e.NOME}`, value: `${e.CODIGO}`})
                        ))
                callback(array)
                
                }).catch(                
                    (error) => {
                        setMSG(logOut(error))
                        setModalMSG(true)
                    }
                    
                    )
            }, 1000);
        }    
  };

useEffect (() => {
    
    const buscaSituacao = async () => {
         await api.get('/situacao',{params:{
            nome : '%'
         }})
         .then(
            result => {    
                setSituacao(result.data)
            })
        .then(async () => {
            await api.get('/status',{params:{
                nome : '%'
             }})
            .then(
                result => {  
                setStatus(result.data)
                setLoading(false);    
            })
            })

        .catch(
            (error) => {
                setLoading(false)
                setMSG(logOut(error))
                setModalMSG(true)
            })
            }
 
    // const buscaStatus = async () => {
    //     await api.get('/status').then(
    //         result => {  
    //         setStatus(result.data)
    //         }
    //     )
    //     .catch(
                
    //         (error) => {
    //             setMSG(logOut(error))
    //             setModalMSG(true)
    //         }
            
    //         )
    //     }
        
        buscaSituacao()
        // buscaStatus()

        setHora(horaProps.replace(/-/g,':'))

        

    },[]);

const onSubmit = async (data) => {

    const response = 
        await api.post('/agenda',{
            COD_PACIENTE: parseInt(codPaciente),
            COD_STATUS: parseInt(data.STATUS),
            COD_SITUACAO: parseInt(data.SITUACAO),
            COD_MEDICO: parseInt(idMed),
            DATA: date,
            HORA: hora,
            VALOR: parseFloat(data.VALOR),
            ENCAIXE: parseInt(encaixe)
    }).then(
        () => {
        setMostrarModal(true)
        }
    ).catch( 
        (error) => {
            setMSG(logOut(error))
            setModalMSG(true)
        }
        
        ) 
      
}

const handleChange = selectedOption => {
    setCodPaciente(selectedOption.value);
    setPacienteNome(selectedOption.label);
}



function handleFecharModal(){
    setMostrarModal(false);
}

function handleFecharModalCadPacNew(){
    setMostrarModalCadPacNew(false);
}

function gravaCadPacNew(){
    let celular;
    if (!celCadPacNew) {
        celular = '';    
    } else celular = celCadPacNew.replace(/[^0-9]+/g, "")
    async function  grava(){
        await api.post('/paciente', {
            COD_EC: 6,
            NOME: nomeCadPacNew,
            CEL : celular
        })
        .then( (result) => {
                            setPacienteNome(result.data.nome)
                            setCodPaciente(result.data.codigo)
                            setMostrarModalCadPacNew(false)}
            )
        .catch(                
            (error) => {
                setMSG(logOut(error))
                setModalMSG(true)
            }) }
    grava();
    
}

return (

<div>    

<div style={{backgroundColor: "#e6eff5"}}>
    <div className="w-50 p-3 mx-auto" >
        <center><h3> Agendamento </h3> </center>
        <center>para o dia {date.split('-').reverse().join('/')}</center>
        <br />
        <Form onSubmit={handleSubmit(onSubmit)}>
   
             <div className="form-row">
            <div className="form-group col-md-12">
                Selecione o Paciente:

                    {!pacienteNome.length &&
                    <Async 
                        // inputValue={textSelect}
                        placeholder="Digite o nome do paciente!"
                        loadOptions={loadOptions}
                        onChange={handleChange}                          
                        
                    />
                        }
                    {pacienteNome.length > 0 && <div>
                    <h3>{pacienteNome}</h3> <div style={{marginTop:"10px"}}><Button onClick={() => setPacienteNome("")}>Trocar</Button></div>
                    </div>
                    
                    }

                    {!pacienteNome.length &&
                        <div style={{marginTop:"10px"}}> <Button   onClick={() => setMostrarModalCadPacNew(true)}> Novo Paciente  </Button></div>
                        }
                    
                </div> 

                
                        
                    
            </div>           
            <div className="form-row">
                <div className="form-group col-md-2" >
                        Hora:
                        <br />
                        {/* <InputMask  
                                mask="99:99:00" 
                                size="10" 
                                name="HORA"  
                                {...register('HORA')} 
                                value={hora}
                                onChange={(e) => setHora(e.target.value)}  // unica maneira para registrar o cep no State
                                alwaysShowMask={false}
                                
                        />                 */}
                        <input type="time"
                        name="HORA"
                        {...register('HORA')}
                        value={hora}
                        onChange={(e) => setHora(e.target.value)}
                        step="2"
                        ></input>
                </div> 
            
                <div className="form-group col-md-5">
                    Procedimento:
                    <br />
                    <Form.Control as="select" size="sm"  id="SITUACAO" {...register("SITUACAO", {value: 1})}>
                            {situacao.map( sit => (
                                <option value={sit.CODIGO}>{sit.DESCRICAO}</option>
                            ))}
                    </Form.Control>
                </div>   
                <div className="form-group col-md-5">
                    Status
                    <br />
                    <Form.Control as="select" size="sm"   id="STATUS" {...register('STATUS', {value: 1})}>
                            {status.map( sta => (
                             
                             <option value={sta.CODIGO}>{sta.DESCRICAO}</option>
                                
                            ))}
                    </Form.Control>
                </div> 
            </div>  
            <div className="form-row" >
                <div className="form-group col-md-2">
                        Valor
                        <Form.Control size="sm" type="text" id="VALOR" {...register('VALOR', {value: null})}/>
                </div>  
            </div>
            <br />
                <div className="form-row" > 
                <div className="form-group col-md-1,5">               
                    <Button type="submit"  onClick={() => handleSubmit(onSubmit)}> Gravar </Button>
                </div>
                   <span> </span>
                <div className="form-group col-md-3" >               
                   <Link to="/agenda/agenda"> <Button variant="danger" > Cancelar  </Button> </Link>
                </div>
            </div>             
        </Form>
    </div>

    <Modal show={mostrarModal} onHide={handleFecharModal} backdrop="static">
        <Modal.Header>
            <center><h3>Agendamento Realizado</h3></center>
        </Modal.Header>
        <Modal.Body>
            <h5>Paciente:</h5> <h2>{pacienteNome}</h2><h5>Dia:</h5> <h2>{date.split('-').reverse().join('/')}</h2> <h5>Às</h5> <h2>{hora}</h2>.
        </Modal.Body>
        <Modal.Footer>
        <Link to="/agenda/agenda"><Button variant="success" >Fechar</Button></Link>
        </Modal.Footer>
    </Modal>

    <Modal show={mostrarModalCadPacNew} onHide={handleFecharModalCadPacNew} backdrop="static">
        <Modal.Header>
            <center><h3>Cadastro de Paciente </h3></center>
        </Modal.Header>
        <Modal.Body>
            Nome:
        <Form.Control style={{textTransform:"uppercase"}} onChange={(e) => setNomeCadPacNew(e.target.value.toUpperCase())} size="sm" type="text" name="nomeCadNew"/>
        
            Cel. Contato:
        <Form.Control onChange={(e) => setCelCadPacNew(e.target.value)} as={IMaskInput} mask="(00)00000-0000" size="sm" type="text" name="celCadNew"/>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="danger"  onClick={() => setMostrarModalCadPacNew(false)}>Cancelar</Button>
        <Button variant="success"  onClick={() => gravaCadPacNew()}>Cadastrar</Button>
        </Modal.Footer>
    </Modal>
    <Modal show={modalMSG}>
                <Modal.Header> 
                    <h3>{MSG}</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="info" onClick={() => setModalMSG(false)}>Ok</Button>
                </Modal.Footer>
    </Modal>  
    <Modal show={loading}>
               <Modal.Body>
                Aguarde....
                </Modal.Body>
    </Modal>  
    </div>
</div>
    
)

}