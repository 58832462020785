// tira os estpaços entre linhas de quando vc envia o pdf com texto para o receituario,
// ele sempre enviava um linha vazia abaixo. com essa função ele remove
//
//{text: Array(1), nodeName: 'P', margin: Array(4), alignment: 'center', style: Array(1), …}
//{text: ' '}
// como acima

export async function removeBlankLines(inputArray) {
    return inputArray.filter((item, index, array) => {
        // Função recursiva para verificar se há texto válido
        function hasValidText(value) {
            if (Array.isArray(value)) {
                return value.some(subItem => hasValidText(subItem.text));
            } else {
                return value.trim() !== '';
            }
        }

        // Verificar se a linha atual é um parágrafo vazio
        const isCurrentLineEmptyParagraph = item.text === '' && item.nodeName === 'P';
        // Verificar se a linha anterior é um parágrafo vazio
        const isPreviousLineEmptyParagraph = array[index - 1] && array[index - 1].text === '' && array[index - 1].nodeName === 'P';

        // Manter a linha se não for uma linha vazia, ou se for um parágrafo vazio, ou se for a linha imediatamente após um parágrafo vazio
        if (isCurrentLineEmptyParagraph || isPreviousLineEmptyParagraph) {
            return true;
        } else {
            return hasValidText(item.text);
        }
    });
}

export function removeFontAttributes(obj) {
    if (Array.isArray(obj)) {
      return obj.map(removeFontAttributes);
    } else if (obj && typeof obj === 'object') {
      const newObj = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (key !== 'font' ) { //&& key !== 'fontSize'
            newObj[key] = removeFontAttributes(obj[key]);
          }
        }
      }
      return newObj;
    } else {
      return obj;
    }
  }
  
  
  