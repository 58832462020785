import React from 'react';
import {useState, useEffect, useRef} from 'react';
import {Modal, Button, Form} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
//import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';

import api from '../conexao.js';
import '../relatorios/table.css';
import logOut from '../logOut';

import pdfMake, { fonts } from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import './impAgenda.css'

export default function ImpAgenda(){

    const [mostraRel, setMostraRel] = useState(false);
    const [medicos, setMedicos] = useState([]);
    const [codMed, setCodMed] = useState();
    const [data1, setData1] = useState();
    const [data2, setData2] = useState();
    const [opcao, setOpcao] = useState(0);
    const [disableBtn, setDisableBtn] = useState(true);
    const [MSG, setMSG] = useState();
    const [modalMSG, setModalMSG] = useState(false);

    
    const [agenda, setAgenda] = useState([]);

    const [agendaTable, setAgendaTable] = useState();

    const impAgenda = useRef(null)

 

    useEffect( () => {   

        async   function buscaMedicos() {
       
             await  api.get('/medico',{params:{
                nome: '%'
             }}).then(listamedicos => {   
                   setMedicos(listamedicos.data)
                   setCodMed(listamedicos.data[0].CODIGO)
       
               }).catch((error) => {
                setDisableBtn(true)
                setMSG(logOut(error))
                setModalMSG(true)
            })
           }
           buscaMedicos();
           setDisableBtn(true)
       },[]);  

    function transformaData(d){
        console.log(d)
        var date = d.replace('T03:00:00.000Z','');
        date = date.split('-').reverse().join('/');   
        return(date)
    }   

    async function handleBuscaAgenda() {

        if (data1 && data2) {
        if (opcao == 0 ) {
            
            await api.get('relimpagenda', {
                params:{
                    cod_medico: codMed,
                    data1: format(data1, 'yyyy-MM-dd'),  // converter data, melhor do BRASIL,
                    data2: format(data2, 'yyyy-MM-dd'),  // converter data, melhor do BRASIL,
                }
            }).then (result => {
                setAgenda(result.data)
                
                if (!result.data[0]) {
                    // alert('Não existe consultas nesse intervalo de data!')
                    setMSG('Não existe consultas nesse intervalo de data!');
                    setModalMSG(true)
                } else {setDisableBtn(false);}
            }).catch((error) => {
                setDisableBtn(true)
                setMSG(logOut(error))
                setModalMSG(true)
            })
            
        } else {
            await api.get('relimpagenda', {
                params:{
                    cod_medico: codMed,
                    data1: format(data1, 'yyyy-MM-dd'),  // converter data, melhor do BRASIL,
                    data2: format(data2, 'yyyy-MM-dd'),  // converter data, melhor do BRASIL,
                    cod_status: opcao
                }
            }).then (
                result => {
                    
                setAgenda(result.data)
                if (!result.data[0]) {
                    // alert('Não existe consultas nesse intervalo de data!')
                    setMSG('Não existe consultas nesse intervalo de data!');
                    setModalMSG(true)
                } else {setDisableBtn(false);}
            }).catch((error) => {
                setDisableBtn(true)
                setMSG(logOut(error))
                setModalMSG(true)
            })  
            
        }    
        
    } else {
        // alert('Preecha as Datas!')
        setMSG('Preecha as Datas!');
        setModalMSG(true) 
}       
    }
    function handleNomeMedico(){
        var nomeMed = medicos.filter(m => m.CODIGO == parseInt(codMed) );
        return (nomeMed[0].NOME);
    }
    
    const PrintReports = (reports) => {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;

        let nomeMed = handleNomeMedico()

        const globalStyle = {// Define a fonte
            fontSize: 12, // Define o tamanho da fonte padrão
            color: 'black', // Define a cor do texto padrão
        };
          
        const infoSub = [
            {text: `Médico: ${nomeMed}`, style: 'subheader'}
        ];

        const infoDate = [
            {text: `De: ${format(data1,'dd/MM/yyyy')}   Até: ${format(data2,'dd/MM/yyyy')}`, style: 'subheader'}
        ]; 

        const reportTitle = [
            {
              text: 'Agenda',
              style: 'header'
            },
        ];
      
        const dados = reports.map((report) => {
            console.log(report)

            let date = transformaData(report.DATA)

            // Retornando os agendamentos
            if (report.VALOR) {
                report.VALOR = parseInt(report.VALOR).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}).toString();
            }
            return [
                {text: date, fontSize: 9, margin: [0, 5]},
                {text: report.HORA, fontSize: 9, margin: [0, 5]},
                {text: report.Paciente.NOME, fontSize: 9, margin: [0, 5]},
                {text: report.Status.DESCRICAO, fontSize: 9, margin: [0, 5]},
                {text: report.Procedimento.DESCRICAO, fontSize: 9, margin: [0, 5]},
                {text: report.Paciente.PLANO, fontSize: 9, margin: [0, 5]},
                {text: report.VALOR, fontSize: 9, margin: [0, 5]},
                
            ] 
        });
      
        const details = [
            {
                table:{
                    // Retorna o header em todas as páginas
                    headerRows: 1, 
                    widths: ['auto', 'auto', 180, 'auto', 80, 58, 'auto'],
                    body: [

                        // Retornando os titulos dos pdfs
                    
                        [
                            {text: 'Data', style: 'tableHeader', fontSize: 10, bold: true},
                            {text: 'Hora', style: 'tableHeader', fontSize: 10, bold: true},
                            {text: 'Nome', style: 'tableHeader', fontSize: 10, bold: true},
                            {text: 'Status', style: 'tableHeader', fontSize: 10, bold: true},
                            {text: 'Proced', style: 'tableHeader', fontSize: 10, bold: true},
                            {text: 'Plano', style: 'tableHeader', fontSize: 10, bold: true},
                            {text: 'Valor', style: 'tableHeader', fontSize: 10, bold: true},
                            
                        ],
                        ...dados
                    ]
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        if (rowIndex === 0) {
                          // Cor da linha do cabeçalho
                          return '#f2f2f2';
                        } else {
                          // Cor da linha das células alternadas
                          return rowIndex % 2 === 0 ? '#ffffff' : '#f9f9f9'; 
                        }
                      },
                      hLineWidth: function (i, node) {
                        return i === 0 ? 1 : 1; // Largura da linha do cabeçalho: 2, Largura da linha do corpo: 1
                      },
                      vLineWidth: function (i, node) {
                        return 1; // Largura da linha vertical para todas as partes: 1
                      },
                     
                     margin: [10, 0]
                  },
            }
        ];
      
        function Rodape(currentPage, pageCount){
          return [
              {
                  text: currentPage + ' / ' + pageCount,
                  alignment: 'right',
                  fontSize: 9,
                  margin: [0, 20, 20, 0], // left, top, right, bottom
              }
          ]
      }
      
      const docDefinitios = {
        pageSize: 'A4',
        pageMargins: [15, 20, 15, 50],
      
        // Watermark representa a marca de agua 
        // watermark: { text: 'MedSolutions', color: 'blue', opacity: 0.3, bold: true, italics: false },
        content: [reportTitle, infoSub, infoDate, details],
        footer: Rodape,
        styles: {
            header: {
                fontSize: 25,
                margin: [0, 0],
                bold: true,
                alignment: 'center',
                width: '100%'
            },
            subheader: {
                fontSize: 12,
                margin: [0, 10],
                alignment: 'left',
                width: '100%'
            },
            tableHeader: {
                margin: [0, 5],
            }
        }
      }

      docDefinitios.defaultStyle = globalStyle;
      
      pdfMake.createPdf(docDefinitios).open();
        
    }

    return(

     <div>

        <div className="impAgenda-formContainer">
        <h2 className="impAgenda-title">Cadastro de Paciente</h2>
        <form className='impAgenda-form '>
            <div className="impAgenda-formGroup">
                <label className="impAgenda-label">Selecione o Médico:</label>
                <Form.Control  size="sm" as="select" value={codMed} onChange={e => setCodMed(e.target.value)} >
                    {medicos.map( med => ( 
                    <option value={med.CODIGO}>{med.NOME}</option>
                    ))}
                 </Form.Control> 
            </div>
            <div className="impAgenda-formGroup ">
                <label className="impAgenda-label">Opções de Status:</label>
                <Form.Control  size="sm" as="select" value={opcao} onChange={e => setOpcao(e.target.value)} >
                    <option value={0}>TODOS</option>
                    <option value={1}>AGUARDANDO</option>
                    <option value={2}>ATENDIDO</option>
                    <option value={3}>EM CONSULTA</option>
                    <option value={4}>MARCADO</option>
                    <option value={5}>NÃO COMPARECEU</option>
                    <option value={6}>DESMARCOU</option>
                </Form.Control>
            </div>
            <div className="impAgenda-formGroup">
                <label className="impAgenda-label">Data de:</label> 
                <DatePicker                         
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Selecione Data"
                    selected={data1}
                    onChange={setData1}                            
                /> 
            </div>
            <div className="impAgenda-formGroup">
                <label className="impAgenda-label">Data até:</label> 
                <DatePicker                         
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Selecione Data"
                    selected={data2}
                    onChange={setData2}                             
                /> 
            </div>
            <div className="impAgenda-formActions">
                <Button variant="outline-primary" onClick={() => handleBuscaAgenda()} >Gerar</Button>
                <Button variant="outline-primary" disabled={disableBtn}  onClick={ () => PrintReports(agenda)} >Imprimir</Button>
            </div>
        </form>
        { agenda &&
            <div ref={impAgenda}>
            <br/>
                <table className="table"  >
                        <thead>
                            <tr>
                                <th scope="col">Data</th>
                                <th scope="col">Hora</th>
                                <th scope="col">Nome</th>
                                <th scope="col">Status</th>
                                <th scope="col">Proced.</th>
                                <th scope="col">Plano</th>
                                <th scope="col">Valor</th>
        
                            </tr>
                        </thead>
                        <tbody>
                            {agenda.map(agendamento => ( // percorre todo o array e joga na tela em formato de tabela

                                <tr key={agendamento.CODIGO}>
                                    <td>{transformaData(agendamento.DATA)}</td>
                                    <td>{agendamento.HORA}</td>
                                    <td>{agendamento.Paciente.NOME}</td>
                                    <td>{agendamento.Status.DESCRICAO}</td>
                                    <td>{agendamento.Procedimento.DESCRICAO}</td>
                                    <td>{agendamento.Paciente.PLANO}</td>
                                   
                                    <td>{ agendamento.VALOR && parseInt(agendamento.VALOR).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                                    
                                </tr>
                                ))
                                }
                        </tbody>
                </table>
        </div>
         } 
    </div>


        {/* // <div style={{backgroundColor: "#e6eff5", padding: "10px"}}>
        //     <br />
        //     <div className="container">
        //         <h3>Imprimir Agenda</h3>
        //             <Form>
        //                 <div className="form-row">
        //                     <div className="form-group col-md-4">
        //                         <br />
        //                             <b>Selecione o Médico:</b>
        //                             <Form.Control  size="sm" as="select" value={codMed} onChange={e => setCodMed(e.target.value)} >
        //                                 {medicos.map( med => ( 
        //                                     <option value={med.CODIGO}>{med.NOME}</option>
        //                                 ))}
        //                             </Form.Control> 
        //                     </div> 
        //                     <div className="form-group col-md-3">
        //                     <br />
        //                         <b>Opções de Status</b>
        //                                     <Form.Control  size="sm" as="select" value={opcao} onChange={e => setOpcao(e.target.value)} >
        //                                         <option value={0}>TODOS</option>
        //                                         <option value={1}>AGUARDANDO</option>
        //                                         <option value={2}>ATENDIDO</option>
        //                                         <option value={3}>EM CONSULTA</option>
        //                                         <option value={4}>MARCADO</option>
        //                                         <option value={5}>NÃO COMPARECEU</option>
        //                                         <option value={6}>DESMARCOU</option>
        //                                     </Form.Control> 
        //                     </div>
        //                 </div>      

        //                 <div className="form-row">
        //                     <div className="form-group col-md-2,5">
        //                             <b>Data de:</b>
        //                             <br/>
                                    // <DatePicker                         
                                    //     peekNextMonth
                                    //     showMonthDropdown
                                    //     showYearDropdown
                                    //     dropdownMode="select"
                                    //     dateFormat="dd/MM/yyyy"
                                    //     placeholderText="Selecione Data"
                                    //     selected={data1}
                                    //     onChange={setData1}  
                                                               
                                    // /> 
                                
        //                     </div>
        //                     <div className="form-group col-md-2,5">
        //                             <b>Data até:</b>
        //                             <br/>
                                    
        //                             <DatePicker
                                        // peekNextMonth
                                        // showMonthDropdown
                                        // showYearDropdown
                                        // dropdownMode="select"
                                        // dateFormat="dd/MM/yyyy"
                                        // placeholderText="Selecione Data"
                                        // selected={data2}
                                        // onChange={setData2}    
                                                             
        //                             /> 
        //                     </div>
        //                 </div>
        //                 <Button variant="outline-primary" onClick={() => handleBuscaAgenda()} >Gerar</Button>
        //                 <> </>
        //                 <Button variant="outline-primary" disabled={disableBtn}  onClick={ () => PrintReports(agenda)} >Imprimir</Button>
        //             </Form>

        //     { agenda &&
        //     <div ref={impAgenda}>
        //     <br/>
        //         <table className="table"  >
        //                 <thead>
        //                     <tr>
        //                         <th scope="col">Data</th>
        //                         <th scope="col">Hora</th>
        //                         <th scope="col">Nome</th>
        //                         <th scope="col">Status</th>
        //                         <th scope="col">Proced.</th>
        //                         <th scope="col">Plano</th>
        //                         <th scope="col">Valor</th>
        
        //                     </tr>
        //                 </thead>
        //                 <tbody>
        //                     {agenda.map(agendamento => ( // percorre todo o array e joga na tela em formato de tabela

        //                         <tr key={agendamento.CODIGO}>
        //                             <td>{transformaData(agendamento.DATA)}</td>
        //                             <td>{agendamento.HORA}</td>
        //                             <td>{agendamento.Paciente.NOME}</td>
        //                             <td>{agendamento.Status.DESCRICAO}</td>
        //                             <td>{agendamento.Procedimento.DESCRICAO}</td>
        //                             <td>{agendamento.Paciente.PLANO}</td>
                                   
        //                             <td>{ agendamento.VALOR && parseInt(agendamento.VALOR).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                                    
        //                         </tr>
        //                         ))
        //                         }
        //                 </tbody>
        //         </table>
        // </div>
        //  } 
    //     </div>*/}
            <Modal show={modalMSG}>
                <Modal.Header> 
                    <h3>{MSG}</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="info" onClick={() => setModalMSG(false)}>Ok</Button>
                </Modal.Footer>
            </Modal> 

        </div>
    )
}
