import { React, useState,useEffect } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { faHospitalUser } from "@fortawesome/free-solid-svg-icons"
import './dashCSS.css'
import { Bar} from 'react-chartjs-2'; 
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import api from '../conexao.js';
import logOut from '../logOut.js';
import { format} from 'date-fns';
import { Modal,Button } from 'react-bootstrap';
import generateRandomColors from './colorsGrafico.js'


ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);


export default function Pacientes(){

    var dataHoje = new Date();
    const [agenda, setAgenda] = useState();
    var planos = [];
    var cod_Med;
    const [modalMSG, setModalMSG] = useState(false);
    const [MSG, setMSG] = useState();
    var dados;
    // const [grafico, setGrafico] = useState([])
    const [nomePlano, setNomePlano] =useState([])
    const [qtdPlano, setQtdPlano] =useState([])
    var dataDia = new Date();
    const [cores,setCores] = useState();
    var grafico;

useEffect( () => {

   async function buscaMedico() { 
    // console.log('passei busca medico')
            // return new Promise((res,rej) => 
         await      api.get('/medico',{
                    params: {
                        nome: '%' 
                    }
                }).then( (med) => {
                    
                  cod_Med = med.data[0].CODIGO
                  // console.log(cod_Med)

             api.get('/imprimeagenda', { 
                   params: {           
                       cod_medico: med.data[0].CODIGO, // funciona somente se o m[edico estiver com codigo 1
                       data: format(dataDia, 'yyyy-MM-dd') 
                   }}).then(result => {
                    //  console.log(result.data)
                    if (result.data) {
                      result.data.map((agendamento, index) => (
                        planos.push({'PlanoSaude': agendamento.Paciente.PLANO})
                      ))
                      // console.log(planos)
                    }
              
                    grafico = (planos.reduce((acc, plano) => { 
                          const { PlanoSaude } = plano; 
                          acc[PlanoSaude] = (acc[PlanoSaude] || 0) + 1; 
                          return acc; 
                        }, {}));
                  
                          // console.log(grafico)
                          setNomePlano(Object.keys(grafico)); 
                          setQtdPlano(Object.values(grafico));
                          setCores(generateRandomColors(Object.keys(grafico).length))
                   })    
                      }).catch(
                          (error) => {
                              setMSG(logOut(error))
                              setModalMSG(true)  
                          })
        }

    buscaMedico();
    
        
},[]);


const backgroundColors = generateRandomColors(nomePlano.length);


const data = { 
  
  labels: nomePlano, 
  datasets: [ { 
    label: '', 
    data: qtdPlano, 
    backgroundColor: cores, 
    hoverBackgroundColor: cores, 
    borderWidth: 1, }, 
  ], };

  const options = { scales: { y: { beginAtZero: true, }, }, };

return (
        <>
        <center>
           <div className='containerDash'> 
                <div>
                    <h5>Pacientes/Convênio</h5>
                    <h6>{format(dataHoje, 'dd/MM/yyyy')}</h6>
                </div>
                <div><FontAwesomeIcon icon={faHospitalUser} size='xl' /></div>
           </div>
          <div 
          style={{width:"330px",height:"180px"}} 
          >
            <Bar  data={data} 
                  options={options}
            />     
          </div>
            
        </center>
        <Modal show={modalMSG}>
        <Modal.Header> 
            <h3>{MSG}</h3>
        </Modal.Header> 
        <Modal.Footer>
            <Button variant="info" onClick={() => setModalMSG(false)}>Ok</Button>
        </Modal.Footer>
    </Modal>
    </>  
    )
}

