
export default function logOut(error){

    // if (error.response.data.includes('banco')) {
    //     // localStorage.removeItem('token')
    //     return('Usuário ou Senha Inválidos!')  //ATIVAR ESSE REGISTRO QUANDO FOR SUBIR FRONT
        
    // } else 
    if (error.message.includes('401')) {
        
        localStorage.removeItem('token')
        localStorage.removeItem('tokenRefresh')
        window.location = '/'       
        return('Sessão Expirada! Faça o Login Novamente.')
    } else
    if (error.message.includes('404')){
        // alert('EndPoint não Existe!')
        return('EndPoint não Existe!');
    } else
    if (error.message.includes('Network Error')){
        // alert('Servidor Fora do ar, Contate o Administrador.')
        return('Erro de Conexão, Contate o Administrador.')
    } else
    if (error.message.includes('banco')){
        // alert('Usuário ou Senha Incorretos.')
        return('Usuário ou Senha Incorretos.');
    }else 
    if (error.message.includes('ERR_CONNECTION_RESET')) {
        return(`${error.message}, Contate o Administrador!`)
    } else
    if (error.message.includes('ERR_NAME_NOT_RESOLVED')) {
        return(`${error.message}, Verifique sua Internet!`)
    }
    { 
        
        return(`${error.message}, Contate o Administrador!`)
    }
}