import React, {useEffect, useState} from 'react';
import { Navbar, Nav, NavDropdown,Button, Container, NavbarBrand } from 'react-bootstrap';
// import {A} from 'hookrouter';
import {Link} from 'react-router-dom'
import api from './conexao';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faBookMedical,faRightFromBracket,faUser,faBook,faPrint,faCloudArrowUp,faHospitalUser,faUserDoctor, faTablets, faFileLines} from "@fortawesome/free-solid-svg-icons"
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons"
import './menu.css'
import logoMed from '../img/medsolutions.png'



function Menu () {

    let tokin = localStorage.getItem('token')  //LE O TOKEN QUE FOI GRAVADO NO LOGIN

    var payload = tokin.split('.')[1];
    var decodedPayload = JSON.parse(window.atob(payload));

    const [expanded, setExpanded] = useState(false); 
    const handleToggle = () => { 
      setExpanded(!expanded) }
    const handleSelect = () => { setExpanded(false); };
      
    function handleLougout(){
      localStorage.removeItem('token')
      localStorage.removeItem('tokenRefresh')
        window.location = '/'
        
    }
    
    return (
    <div>
       <div > 
       <div >  

       <Navbar bg="light" expand="lg" fixed="top" expanded={expanded}>
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle}/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto" onSelect={handleSelect}>

            <NavDropdown title="Início" id="basic-nav-dropdown">
              <NavDropdown.Item onClick={handleSelect}><FontAwesomeIcon icon={faUser} size="lg" /> <span /> Usuario: {decodedPayload.param2}</NavDropdown.Item>
              <NavDropdown.Item onClick={() => {handleLougout()}}><FontAwesomeIcon icon={faRightFromBracket} size="lg" /><span /> LogOff</NavDropdown.Item>
            </NavDropdown>
            
            <NavDropdown title="Cadastro" id="basic-nav-dropdown">
              <NavDropdown.Item onClick={handleSelect} as={Link} to="/paciente/paciente"><FontAwesomeIcon icon={faHospitalUser} size="lg" /><span /> Paciente</NavDropdown.Item>
              <NavDropdown.Item onClick={handleSelect} as={Link} to="/medico/medico"><FontAwesomeIcon icon={faUserDoctor} size="lg" /><span /> Médico</NavDropdown.Item>
              <NavDropdown.Item onClick={handleSelect} as={Link} to="/medicamento/medicamento"><FontAwesomeIcon icon={faTablets} size="lg" /><span /> Medicamento</NavDropdown.Item>
              <NavDropdown.Item onClick={handleSelect} as={Link} to="/texto/texto"><FontAwesomeIcon icon={faFileLines} size="lg" /><span /> Texto</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Movimento" id="basic-nav-dropdown">
              <NavDropdown.Item onClick={handleSelect} as={Link} to="/agenda/agenda"><FontAwesomeIcon icon={faBook} size="lg" /><span /> Agenda</NavDropdown.Item>
              <NavDropdown.Item onClick={handleSelect} as={Link} to="/fichapaciente/fichapacienteacesso"><FontAwesomeIcon icon={faBookMedical} size="lg"/><span /> Ficha do Paciente</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Configurações" id="basic-nav-dropdown">
              <NavDropdown.Item onClick={handleSelect} as={Link} to="/agenda/confagenda"><FontAwesomeIcon icon={faBook} size="lg" /><span /> Configuração da Agenda</NavDropdown.Item>
              <NavDropdown.Item onClick={handleSelect} as={Link} to="/whatsApp/whatsapp"><FontAwesomeIcon icon={faWhatsapp} size="lg" /><span /> Configuração do WhatsApp</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Relatórios" id="basic-nav-dropdown">
              <NavDropdown.Item onClick={handleSelect} as={Link} to="/relatorios/impagenda"><FontAwesomeIcon icon={faPrint} size="lg" /><span /> Imprimir Agenda</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Utilitários" id="basic-nav-dropdown">
               <NavDropdown.Item onClick={handleSelect}><FontAwesomeIcon icon={faCloudArrowUp} size="lg" /> <span /> Backup </NavDropdown.Item>
            </NavDropdown>

          </Nav>
        </Navbar.Collapse>
        <Navbar.Brand className="ms-auto">
          <Link to="/">
            <img src={logoMed} onClick={handleSelect} width="200" height="56" className="d-inline-block align-top" alt="Logo" />
          </Link>
        </Navbar.Brand>
      </Container>
    </Navbar>
        
       
     </div>
     
    </div>
        
        
 
    </div>

    
    )
}


export default Menu; //retornar funcao sempre sem ()