import React, { useState, useEffect } from 'react';
import { Modal, Button, Form} from 'react-bootstrap';
import Async from 'react-select/async';
import api from '../conexao.js';
import { Link, useNavigate } from 'react-router-dom';
import logOut from '../logOut';
import './FichaPaciente.css'

export default function FichaPacienteAcesso(){

    const [codPaciente, setCodPaciente] = useState();
    const [pacienteNome, setPacienteNome] = useState();
    const [codMed, setCodMed] = useState();
    const [medicos, setMedicos] = useState([]);
    const [modalMSG, setModalMSG] = useState(false);
    const [MSG, setMSG] = useState();

    const nave = useNavigate();
    


useEffect( () => {   

 async   function buscaMedicos() {

      await  api.get('/medico',{params:{
        nome: '%'
      }}).then(listamedicos => {   
        // console.log(listamedicos.data[0].CODIGO)
            setMedicos(listamedicos.data)
            setCodMed(listamedicos.data[0].CODIGO)

        }).catch((error) => {
            setMSG(logOut(error))
            setModalMSG(true)
        })  
    }
    buscaMedicos();
},[]);   


const loadOptions = (inputValue, callback) => {
        
        if (!inputValue || inputValue.length <= 4)  {
            callback([])
        } else {
        setTimeout(() => {
            api.get('/paciente', { 
                params: {           //BUSCA POR PARAMETRO ? NOME  
                nome: inputValue
                }}).then( result => {           // APARTIR DA 4 LETRA ELE COMEÇA A BUSCAR NO BANCO
                    const array = [];           // PENSAR EM UMA MANEIRA MELHOR PARA NAO FAZER VÁRIAS BUSCAS NA API
                    result.data.map(e  => (
                        array.push({label: `${e.NOME}`, value: `${e.CODIGO}`})
                        ));
                callback(array)
                
                }).catch( (error) => {
                    setMSG(logOut(error))
                    setModalMSG(true)
                }) 
            }, 1000);
        }    
};

const handleChange = selectedOption => {
    setCodPaciente(selectedOption.value);
    setPacienteNome(selectedOption.label);
}

function avanca(){  
    if (pacienteNome) {  nave("/fichapaciente/fichapaciente/" + parseInt(codMed) + "/" + parseInt(codPaciente) )} //chama prox tela
    else {
        setMSG('Pesquise o paciente!')
        setModalMSG(true)}
}

    return(
    <div >
        <Form onSubmit={() => avanca()}>
            
        <div className="fichaPacienteAcesso-formContainer">
            <br /> 
            <h2 className="impAgenda-title">Ficha do Paciente</h2>
        <div className="form-row">
            
            <div className="form-group col-md-4">
            <br />
                Selecione o Médico:
                
                <Form.Control    as="select" value={codMed} onChange={e => setCodMed(e.target.value)} 
                        >
                            {medicos.map( med => (
                                <option value={med.CODIGO}>{med.NOME}</option>
                            ))}
                        </Form.Control> 
                </div> 
                <div className="form-group col-md-6">
                <br />
                Selecione o Paciente:      
                    <div className='async'>   
                        <Async  
                            placeholder="Digite o nome do paciente!"
                            loadOptions={loadOptions}
                            onChange={handleChange}                        
                        />
                    <br />
                    <Button size="lg" onClick={() => { avanca()  }}>
                    Abrir
                </Button> 
                    </div>    
                </div> 

            </div> 
            {/* <Link to={"/fichapaciente/fichapaciente/" + parseInt(codMed) + "/" + parseInt(codPaciente)}> */}
            
                {/* </Link>    */}
            </div> 
        </Form>
        <Modal show={modalMSG}>
                <Modal.Header> 
                    <h3>{MSG}</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="info" onClick={() => setModalMSG(false)}>Ok</Button>
                </Modal.Footer>
            </Modal> 
    </div>
    
    )
};

