import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { faGears } from "@fortawesome/free-solid-svg-icons"
import './dashCSS.css'



export default function DashAvisos(){

    
    return (
    
        <>
        <center>
           <div className='containerDash'> 
                <div>
                    <h5>Novo Recurso</h5>
                    <h6>14/01/2025</h6>
                </div>
                <div><FontAwesomeIcon icon={faGears} size='xl' /></div>
           </div>
            <tr>
                <td className='containerTexto'>
                    <ol> 
                        <h6>Atualizações</h6> 
                        <li>Função WhatsApp</li>
                        <li>Assinatura Manual</li>
                        <li>Melhorias no Layout</li>    
                        
                    </ol>
                </td>
            </tr>
            
        </center>

    </>  
   
    )
}

