import React, { useState, useEffect }from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Button, Form, Jumbotron } from 'react-bootstrap';
import api from '../conexao.js';
import '../../App.css';
import { useNavigate } from 'react-router-dom';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState,  convertToRaw, ContentState } from 'draft-js';
import logOut from '../logOut';
import './CadastroTexto.css'






export default function CadTexto(){

        const nave = useNavigate();

const {register, handleSubmit} = useForm( );  //REGISTRANDO AS FUNÇÕES DO USEFORM
const [exibirModal, setExibirModal] = useState(false); // const para definir se a modal aparece ou nao
const [tipoTexto, setTipoTexto] = useState([]);
const [erroGetTipo] = useState();
const [codTipoTexto , setCodTipoTexto] = useState();

const [html, setHTML] = useState('');
const [contentBlock] = useState(htmlToDraft(html));
const [contentState] = useState(ContentState.createFromBlockArray(contentBlock.contentBlocks));
const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));

const [textoBanco, setTextoBanco] = useState();
const [modalMSG, setModalMSG] = useState(false);
const [MSG, setMSG] = useState();
const toolBarValues = {
        options: ["inline", "fontSize", "colorPicker", "textAlign"],
        inline: {
          inDropdown: false,
          options: ["bold", "italic", "underline", "strikethrough"]
        }
    };
// funçao que enche variavel editorState com texto do Editor.
function onEditorStateChange(editorState) {
        setEditorState(editorState)
        setTextoBanco(draftToHtml(convertToRaw(editorState.getCurrentContent())));
        // esta joga o texto do editor, para variavel textoBanco para ir para o banco
      };


function handleFecharModal(){
        setExibirModal(false); // inicia faalse
        nave('/texto/texto'); // vai para pagina anterior
     //   window.location.reload(); //da um load na pagina
}

function handleVoltar(){
        nave('/texto/texto');
     //   window.location.reload(); 
}

useEffect(() => {

        api.get('/tipotexto').then(resultTT => {
                // LAÇO PARA PREENCHER <SELECT> tipo texto
        setTipoTexto(resultTT.data);
        setCodTipoTexto(resultTT.data[0].CODIGO)
        // console.log('Erro - repetição tipo texto'); //coloquei variavel erroGetTipo, pq o useEffect fica em loop

        onEditorStateChange(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft('<p></p>'))));
        // só consegui fazer gravar vazio assim

        }).catch((error) => {
                setMSG(logOut(error))
                setModalMSG(true)
            })

},[erroGetTipo])

const onSubmit = async (data) => {
        if (!data.COD_TIPO_TEXTO) {data.COD_TIPO_TEXTO = codTipoTexto}
        const response =
                await api.post('/texto', {
                NOME: data.NOME.toUpperCase(),
                COD_TIPO_TEXTO: parseInt(data.COD_TIPO_TEXTO),
                TEXTO: textoBanco

        }).then( () =>
        setExibirModal(true)
        )
        .catch((error) => {
                setMSG(logOut(error))
                setModalMSG(true)
            })
      
}   
        
    return(
        
    <div >

        <div className="cadastroTexto-formContainer">
              <h2 className="cadastroTexto-title">Cadastro do Texto</h2>
              <form onSubmit={handleSubmit(onSubmit)} className='cadastroTexto-form '>
                <div className="cadastroTexto-formGroup">
                  <label className="cadastroTexto-label">Nome:</label>
                  <input className="cadastroTexto-input" type="text" name="NOME" maxLength={45} style={{textTransform:"uppercase"}} required  {...register('NOME')}  />
                </div>
                <div className="cadastroTexto-formGroup">
                    <label className="cadastroTexto-label">Tipo do Texto:</label>
                    <Form.Control  size="sm" as="select" name="COD_TIPO_TEXTO" {...register('COD_TIPO_TEXTO')}>
                            {tipoTexto.map( tipotexto => (
                                <option value={tipotexto.CODIGO}>{tipotexto.DESCRICAO}</option>))}
                    </Form.Control>
                </div>
                <label className="cadastroTexto-label">Texto:</label>
                <div className="overflow-scroll" style={{height:"500px",backgroundColor:'white',width:'100%'}} >
                        <Editor 
                            editorState={editorState}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            onEditorStateChange={onEditorStateChange}
                            placeholder="Digite o Texto"
                            toolbar={toolBarValues}
                        />
                </div>
                <div className="cadastroPaciente-formActions">
                    <Button className="btn btn-primary" type="submit" >Gravar</Button>                
                    <Button type="button" className="btn btn-secondary" onClick={() => {handleVoltar()}}>Cancelar</Button>
                </div> 
            </form>
        </div>
         <Modal show={exibirModal} onHide={handleFecharModal} backdrop="static">
                <Modal.Header>
                        <h2> Cadastro de Texto </h2>
                </Modal.Header>
                <Modal.Body>
                        Texto Gravado!
                </Modal.Body>
                <Modal.Footer>
                        <Button variante="success" onClick={() => handleFecharModal()}> Ok </Button>
                </Modal.Footer>
                
        </Modal>
        <Modal show={modalMSG}>
                <Modal.Header> 
                    <h3>{MSG}</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="info" onClick={() => setModalMSG(false)}>Ok</Button>
                </Modal.Footer>
            </Modal> 
        </div>
        
    )
};


