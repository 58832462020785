import novo from '../../img/novo.jpg'
import rodape from '../../img/rodape.jpg'
// import AWS from 'aws-sdk';
// import {sendFile} from '../whatsApp/whatsapp'






export default function PrintReports(infos, nomeMed, nomePac, titlePag ,date,crmMed,propsCab,propsRod,assinatura){
    const pdfMake = require('pdfmake/build/pdfmake');
    const pdfFonts = require('pdfmake/build/vfs_fonts');
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    // pdfMake.addVirtualFileSystem(pdfFonts);

    // pdfMake.fonts = { Arial: {  normal:   'fonts/Arial.ttf', 
    //                             bold: 'fonts/Arial-Bold.ttf', 
    //                             italics: 'fonts/Arial-Italic.ttf', 
    //                             bolditalics: 'fonts/Arial-BoldItalic.ttf' },

    //                 Roboto: {  normal:   'fonts/Roboto-Regular.ttf', 
    //                         bold: 'fonts/RobotoBold.ttf', 
    //                         italics: 'fonts/RobotoItalic.ttf', 
    //                         bolditalics: 'fonts/RobotoBoldItalic.ttf' } 
    //                 };
    var snow;
    var snow2;
    console.log(pdfMake.fonts)

    
    // CRIA UM VALOR RAMDOMICO PARA O ARQUIVO NUNCA ser O MESMO E O CACHE NAO ACONTECER
   var nocache = new Date(); 
    // VERIFICA SE O MEDICO TEM CABECALHO OU RODAPE, SE SIM ASSOCIA A VARIAVEL QUE PRINTA NO PDF
    if (propsCab == 0) {snow    = novo}     else    {snow   = 'https://imgmedsolutions.s3.sa-east-1.amazonaws.com/'+ 'cab+' + nomeMed.substring(0,10).replace(' ','+').toLowerCase() + '.jpg?' + nocache.getTime()}
    if (propsRod == 0) {snow2   = rodape}   else    {snow2  = 'https://imgmedsolutions.s3.sa-east-1.amazonaws.com/'+ 'rod+' + nomeMed.substring(0,10).replace(' ','+').toLowerCase() + '.jpg?' + nocache.getTime()}
    
    const globalStyle = {// Define a fonte
        fontSize: 12, // Define o tamanho da fonte padrão
        color: 'black', // Define a cor do texto padrão
    };

    const title = [
        {image: 'snowIMG', width: 530, height: 60, alignment: 'center', margin: [0, 40]} // PARTE EM BRANCO EM CIMA
    ]
    
  

    const reportTitle = [
        {
          text: titlePag,
          style: 'header'
        },
    ];

    const infoPdf = [
        {text: `Paciente: ${nomePac} `, style: 'subheader'}
    ];

    const infoSub = [
        {text: `Data: ${date}`, style: 'subheader'}
    ];

    const assina = [
        
        {   
            image: assinatura,
            width: 75, height: 75,
            alignment: 'center',
            margin: [0, 0, 0, -20] // ALTURA DO RODAPÉ
            
        }
    ]

    const infoMed = [
        {text: `${nomeMed}`, style: 'header2'}
 
    ];
    const infoCrmMed = [
        {text: `${crmMed}`, style: 'headerCRM'} // AQUI É A VARIAVEL QUE VAI APARECER E ONDE
    ]
  
    // const details = [
    //     {
    //         text: infos
    //         // content: infos
    //     }
    // ];
  
    function Rodape(currentPage, pageCount){
        return [
            {                
                image: 'snowIMG2',
                width: 530, 
                alignment: 'center', 
                margin: [0, 0, 0, 10] // ALTURA DO RODAPÉ
                // left, top, right, bottom
            }
        ]
    }
  
  const docDefinitios = {
    info: {
        title: 'Receituario '+ nomePac,
    },

    pageSize: 'A4',
    pageMargins: [15, 100, 15, 100],
  
    // Watermark representa a marca de agua 
    // watermark: { text: 'MedSolutions', color: 'blue', opacity: 0.3, bold: true, italics: false },
    header: [title],
    content: [reportTitle, infoSub, infoPdf, infos , assina ? {   
        image: assinatura,
        width: 75, height: 75,
        alignment: 'center',
        margin: [0, 0, 0, -20] // ALTURA DO RODAPÉ
        
    }:{},infoMed, infoCrmMed],
    images: {
        // 
    
        snowIMG: snow,
        snowIMG2: snow2,
        assina: assinatura,
        
        strawberries: {
  
        url: novo,
        
          headers: {
            myheader: '123',
            myotherheader: 'abc',
          },
        },
      },
    footer: Rodape,
    styles: {
        header: {
            fontSize: 25,
            margin: [0, 30, 0, 0], //
            bold: true,
            alignment: 'center',
            width: '100%'
        },
        header2: {  // NOME MÉDICO, SE TIRAR MARGIN ELE COLA NO TEXTO DOS REMEDIOS
            fontSize: 12,
            margin: [0, 30, 0, 0], 
            bold: true,
            alignment: 'center',
            width: '100%'
        },
        headerCRM: {  // AQUI DECLADA COMO VAI FICAR O CRM
            fontSize: 10,
            margin: [0, 7, 0, 0], 
            bold: true,
            alignment: 'center',
            width: '100%'
        },        
        subheader: {
            fontSize: 12,
            margin: [0, 10],
            alignment: 'left',
            width: '100%'
        },
        tableHeader: {
            margin: [0, 5],
        }
    }
  }

  docDefinitios.defaultStyle = globalStyle;

return(docDefinitios)
}
  
